import React from 'react';
import { addColumnSortLabels } from '@/presentation/components/commons/labels';
import { getLocaleTimestamp } from '@/presentation/common-utils/common-utils';
import { StatusIndicator } from '@cloudscape-design/components-themed/components';
import { providerColumnDefinition, providerFilteringProperty, serviceFilteringProperty, xkidFilteringProperty } from '@/presentation/pages/common-config';
import { PROXY_MODE_TYPE_LABEL } from "@/presentation/pages/proxies/proxies-types";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleCheck, faCircleXmark } from '@fortawesome/free-regular-svg-icons';
import { MONITORING_DATERANGE_OPTIONS, MONITORING_DATETIME_OPTIONS, MONITORING_TABLE_OPTIONS, MONITORING_TABLE_SEARCH_QUERY, SortDirection } from '@/presentation/storage';
export const MonitoringListFilteringProperties = [
    {
        key: 'valid',
        operators: [':', '!:', '=', '!='],
        propertyLabel: 'Valid',
        groupValuesLabel: 'Validity values'
    },
    xkidFilteringProperty,
    {
        key: 'proxy',
        operators: [':', '!:', '=', '!='],
        propertyLabel: 'Proxy',
        groupValuesLabel: 'Proxy values'
    },
    {
        key: 'identity',
        operators: [':', '!:', '=', '!='],
        propertyLabel: 'Identities',
        groupValuesLabel: 'Identity values'
    },
    providerFilteringProperty,
    serviceFilteringProperty,
    {
        key: 'action',
        operators: ['=', '!=', ':', '!:'],
        propertyLabel: 'Action',
        groupValuesLabel: 'Action values'
    },
    {
        key: 'actionType',
        operators: [':', '!:', '=', '!='],
        propertyLabel: 'Action Type',
        groupValuesLabel: 'Action Type values'
    },
];
export const monitoringListFilteringOptions = [
    {
        propertyKey: 'valid',
        value: 'True'
    },
    {
        propertyKey: 'valid',
        value: 'False'
    }
];
const makeLogValidity = (item) => {
    const { validityStatus, proxyMode } = item;
    switch (true) {
        case (validityStatus):
            return (React.createElement(FontAwesomeIcon, { icon: faCircleCheck, size: "lg", color: "#037F0C", title: "Allowed" }));
        case (!validityStatus && (proxyMode === PROXY_MODE_TYPE_LABEL.LEARNING || (item === null || item === void 0 ? void 0 : item.learningMode))):
            return (React.createElement(FontAwesomeIcon, { icon: faCircleCheck, size: "lg", color: "#FF8D1D", title: "Learning Mode" }));
        case (!validityStatus && proxyMode !== PROXY_MODE_TYPE_LABEL.LEARNING && !(item === null || item === void 0 ? void 0 : item.learningMode)):
            return (React.createElement(FontAwesomeIcon, { icon: faCircleXmark, size: "lg", color: "#D91515", title: "Blocked" }));
        default:
            return null;
    }
};
export const MonitoringListCardsColumnDefinitions = addColumnSortLabels([
    {
        id: 'validityStatus',
        header: '',
        cell: item => {
            return (React.createElement("div", { className: "flex justify-center" }, makeLogValidity(item)));
        },
        width: 80
    },
    {
        id: 'timestamp',
        sortingField: 'timestamp',
        sortingDescending: true,
        header: 'Timestamp',
        cell: (item) => {
            return getLocaleTimestamp(item === null || item === void 0 ? void 0 : item.timestamp);
        }
    },
    {
        id: 'xkid',
        sortingField: 'xkid',
        header: 'xkid',
        cell: (item) => {
            return (item === null || item === void 0 ? void 0 : item.xkid) || '-';
        }
    },
    {
        id: 'proxy',
        sortingField: 'proxy',
        header: 'Proxy',
        cell: (item) => {
            return (item === null || item === void 0 ? void 0 : item.proxy) || '-';
        }
    },
    {
        id: 'identity',
        sortingField: 'identity',
        header: 'Identities',
        cell: (item) => {
            return (item === null || item === void 0 ? void 0 : item.identity) || '-';
        }
    },
    providerColumnDefinition,
    {
        id: 'service',
        sortingField: 'service',
        header: 'Service',
        cell: (item) => {
            if ((item === null || item === void 0 ? void 0 : item.serviceStatus) === "disabled") {
                return (React.createElement("div", { className: "flex" },
                    React.createElement("div", { className: "flex-none mr-2" }, (item === null || item === void 0 ? void 0 : item.service) || '-'),
                    React.createElement("div", { className: "flex-none" },
                        React.createElement(StatusIndicator, { type: "error", colorOverride: "grey" }, "Disabled"))));
            }
            return (item === null || item === void 0 ? void 0 : item.service) || '-';
        }
    },
    {
        id: 'action',
        sortingField: 'action',
        header: 'Action',
        cell: (item) => {
            return ((item === null || item === void 0 ? void 0 : item.action) ? (React.createElement("span", null,
                React.createElement("span", { className: "px-2 py-1" }, item === null || item === void 0 ? void 0 : item.action),
                (item === null || item === void 0 ? void 0 : item.isCFNRedirectionExist) && (React.createElement("span", { className: "inline-flex items-center rounded-md bg-blue-100 px-2 py-1 text-xs font-medium text-black-700" }, "CFN SCAN"))))
                : '-');
        },
    },
    {
        id: 'actionType',
        sortingField: 'actionType',
        header: 'Action Type',
        cell: (item) => (item === null || item === void 0 ? void 0 : item.actionType) || '-'
    },
]);
export const MonitoringListTablePreferences = {
    pageSize: 30,
    visibleContent: ['validityStatus', 'timestamp', 'xkid', 'identity', 'provider', 'service', 'action', 'table-actions'],
    wrapLines: false,
    custom: {
        autoResize: true
    }
};
export const MonitoringListTableVisibleContentOptions = [
    {
        label: null,
        options: [
            { id: 'validityStatus', label: 'Valid', editable: false },
            { id: 'timestamp', label: 'Timestamp', editable: true },
            { id: 'xkid', label: 'Xkid', editable: true },
            { id: 'proxy', label: 'Proxy', editable: true },
            { id: 'identity', label: 'Identities', editable: true },
            { id: 'provider', label: 'Provider', editable: true },
            { id: 'service', label: 'Service', editable: true },
            { id: 'action', label: 'Action', editable: true },
            { id: 'actionType', label: 'Action Type', editable: true }
        ]
    }
];
export const MonitoringListTablePageSizeOptions = [
    { value: 10, label: '10 Logs' },
    { value: 30, label: '30 Logs' },
    { value: 50, label: '50 Logs' }
];
export const MonitoringListInitialTableOptions = {
    tableOptionsStorage: MONITORING_TABLE_OPTIONS,
    queryStorage: MONITORING_TABLE_SEARCH_QUERY,
    sortDirection: SortDirection.DESC,
    sortField: 'Timestamp',
    dateTimeOptionsStorage: MONITORING_DATETIME_OPTIONS,
    dateRangeStorage: MONITORING_DATERANGE_OPTIONS
};
export const MonitoringListDefaultDatetimeOptions = {
    startDateTime: 'now-4h',
    endDateTime: 'now'
};
export const MonitoringListDefaultDateRangeFilter = {
    value: {
        amount: 4,
        unit: 'hour',
        type: 'relative',
        key: 'last-4-hours'
    }
};
export const generateFilterObject = (dataArr, propertyExistingName, propertyNewName) => {
    const filterItems = dataArr === null || dataArr === void 0 ? void 0 : dataArr.map(item => {
        const temObject = {
            operator: item === null || item === void 0 ? void 0 : item.operator,
            value: item === null || item === void 0 ? void 0 : item.value
        };
        if (item === null || item === void 0 ? void 0 : item[propertyExistingName]) {
            return Object.assign({ [propertyNewName]: item === null || item === void 0 ? void 0 : item[propertyExistingName] }, temObject);
        }
        return temObject;
    });
    return filterItems;
};
export const customUrlOrder = ['xkid', 'from_ts', 'to_ts', 'sort_field', 'sort_order', 'filters'];
