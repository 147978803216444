import React, { useContext } from 'react';
import { RemoteAuditLogs, RemoteCloudTenants, RemoteDomainACLs, RemoteMemberships, RemoteNotificationsDestinations, RemoteOpenPolicy, RemoteOrganization, RemoteProviders, RemoteRoles, RemoteServices, RemoteGlobalPolicyFunctions, RemoteSSO } from '@/data/usecases';
import { makeAuditLogsAPIUrl, makeManagementAPIUrl, makeNotificationsDestinationsAPIUrl, makeOpenPolicyAPIUrl, makeResendUserVerificationEmail, makeSSOAPIUrl } from '@/presentation/main/factories/http';
import { makeAuthorizeHttpClientDecorator } from '@/presentation/main/factories/decorators';
import { AttributeKeyValueValidator, CloudTenantsHandler, ProvidersHandler, SettingsHandler, StringValidator } from '@/presentation/handlers';
import { useAuth0 } from '@/lib/auth0';
import { RemoteUsers } from '@/data/usecases/users/remote-users';
import { DomainACLHandler } from '@/presentation/handlers/pages/domain-acl-handler';
const defaultContext = {
    settings: new SettingsHandler(null),
    providers: new ProvidersHandler(null),
    tenants: new CloudTenantsHandler(null),
    domainACL: new DomainACLHandler(null),
    cloudTenantNameValidator: new StringValidator(),
    domainACLNameValidator: new StringValidator(),
    orgNameValidator: new StringValidator(),
    orgIDValidator: new StringValidator(),
    domainValidator: new StringValidator(),
    domainActionMappingsValidator: new AttributeKeyValueValidator(),
    allowedDomainsValidator: new StringValidator(),
    destinationNameValidator: new StringValidator(),
    destinationDescriptionValidator: new StringValidator(),
    destinationURLValidator: new StringValidator()
};
export const SettingsHandlersProviderContext = React.createContext(defaultContext);
export const useSettingsHandlers = () => useContext(SettingsHandlersProviderContext);
export const SettingsHandlersProvider = ({ children }) => {
    const { getTokenSilently } = useAuth0();
    const addUserURL = makeManagementAPIUrl('/org/users');
    const deleteUserURL = makeManagementAPIUrl('/org/user');
    const resendVerificationURL = makeResendUserVerificationEmail('/org/user/resend-verify');
    const authorizeHttpClient = makeAuthorizeHttpClientDecorator();
    const settingsRemotes = {
        organization: new RemoteOrganization(getTokenSilently),
        globPolicy: new RemoteGlobalPolicyFunctions(getTokenSilently),
        memberships: new RemoteMemberships(getTokenSilently),
        users: new RemoteUsers(addUserURL, deleteUserURL, resendVerificationURL, authorizeHttpClient),
        roles: new RemoteRoles(makeManagementAPIUrl('/org/users'), authorizeHttpClient, getTokenSilently),
        services: new RemoteServices(getTokenSilently),
        openPolicy: new RemoteOpenPolicy(makeOpenPolicyAPIUrl(), authorizeHttpClient),
        notificationDestinations: new RemoteNotificationsDestinations(makeNotificationsDestinationsAPIUrl(), authorizeHttpClient),
        auditLogs: new RemoteAuditLogs(makeAuditLogsAPIUrl(), authorizeHttpClient),
        getSSO: new RemoteSSO(makeSSOAPIUrl(), authorizeHttpClient)
    };
    const settingsHandler = new SettingsHandler(settingsRemotes);
    const orgNameValidator = new StringValidator().notEmpty();
    const cloudTenantNameValidator = new StringValidator().notEmpty();
    const domainACLNameValidator = new StringValidator().notEmpty();
    const orgIDValidator = new StringValidator().notEmpty();
    const domainValidator = new StringValidator().notEmpty();
    const allowedDomainsValidator = new StringValidator().isValidAllowedDomain();
    const destinationNameValidator = new StringValidator().notEmpty();
    const destinationDescriptionValidator = new StringValidator().notEmpty().isValidResourceDesc();
    const destinationURLValidator = new StringValidator().notEmpty().isValidURL();
    const domainActionMappingsValidator = new AttributeKeyValueValidator().ignoreEmptyPairs().haveValidAttributeTextKeyDomainNamesValueSelectPairs();
    const providersRemotes = {
        providers: new RemoteProviders(getTokenSilently)
    };
    const providersHandler = new ProvidersHandler(providersRemotes);
    const tenantRemote = new RemoteCloudTenants(getTokenSilently);
    const cloudTenantsHandler = new CloudTenantsHandler(tenantRemote);
    const domainACLRemote = new RemoteDomainACLs(getTokenSilently);
    const domainACLHandler = new DomainACLHandler(domainACLRemote);
    return React.createElement(SettingsHandlersProviderContext.Provider, { value: {
            settings: settingsHandler,
            providers: providersHandler,
            tenants: cloudTenantsHandler,
            domainACL: domainACLHandler,
            cloudTenantNameValidator,
            domainACLNameValidator,
            orgIDValidator,
            orgNameValidator,
            domainValidator,
            allowedDomainsValidator,
            destinationNameValidator,
            destinationDescriptionValidator,
            destinationURLValidator,
            domainActionMappingsValidator
        } }, children);
};
