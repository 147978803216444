import React from "react";
import { ContentLayout } from "@cloudscape-design/components-themed/components";
const ContentFormLayoutWrapper = (props) => {
    const headerContainer = (props === null || props === void 0 ? void 0 : props.header) ? (React.createElement("div", { className: "layout-form-custom-header-class" },
        " ",
        props.header,
        " ")) : null;
    return (React.createElement("div", { className: "layout-form-custom-class" },
        React.createElement(ContentLayout, Object.assign({}, props, { header: headerContainer }), props.children)));
};
export default ContentFormLayoutWrapper;
