var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { PagesBaseHandler } from '@/presentation/handlers';
import { PROXY_MODE_TYPE_LABEL } from "@/presentation/pages/proxies/proxies-types";
import { getPropertyFilteringOptions } from '@/presentation/common-utils/common-utils';
export class RuleSimulatorHandler extends PagesBaseHandler {
    constructor(remotes) {
        super();
        this.remotes = remotes;
    }
    getFormattedEntities(ruleID, logs) {
        var _a;
        const servicesFilterMapList = [];
        const providersFilterMapList = [];
        const rulesSimulationList = (_a = logs === null || logs === void 0 ? void 0 : logs.data) === null || _a === void 0 ? void 0 : _a.map(simulation => {
            var _a, _b, _c, _d, _e;
            // Update filter map lists
            servicesFilterMapList.push(simulation.Data.Service);
            providersFilterMapList.push(simulation.Data.Provider);
            const proxyMode = ((_a = simulation === null || simulation === void 0 ? void 0 : simulation.Data) === null || _a === void 0 ? void 0 : _a.ProxyMode) ? this.getProxyModeWithSpace((_b = simulation === null || simulation === void 0 ? void 0 : simulation.Data) === null || _b === void 0 ? void 0 : _b.ProxyMode)
                : (((_c = simulation === null || simulation === void 0 ? void 0 : simulation.Data) === null || _c === void 0 ? void 0 : _c.LearningMode) ? PROXY_MODE_TYPE_LABEL.LEARNING : PROXY_MODE_TYPE_LABEL.DEFAULT_BLOCK_PREVENTATIVE);
            return {
                id: simulation.Xkid,
                ruleID: ruleID,
                ruleStateChange: simulation.RuleStateChange,
                timestamp: simulation.Timestamp,
                xkid: simulation.Xkid,
                proxy: simulation.Data.ProxyName,
                proxyMode: proxyMode,
                provider: simulation.Data.Provider,
                service: simulation.Data.Service,
                identity: simulation.Data.IdentityName,
                action: (_e = (_d = simulation.Data) === null || _d === void 0 ? void 0 : _d.Action) !== null && _e !== void 0 ? _e : '',
                host: simulation.Data.Host,
                method: simulation.Data.Method,
                path: simulation.Data.Path,
                ruleSimulation: simulation
            };
        });
        return [{
                rulesSimulationList: rulesSimulationList,
                servicesFilteringOptions: getPropertyFilteringOptions(servicesFilterMapList, 'service'),
                providersFilteringOptions: getPropertyFilteringOptions(providersFilterMapList, 'provider')
            }];
    }
    filterByDateTime(startDateTime, endDateTime, formattedData) {
        try {
            const oneMinute = 1;
            const milliseconds = 1000 * 60 * oneMinute;
            const fromDateTime = Math.floor(new Date(startDateTime).getTime() / milliseconds);
            const toDateTime = Math.floor(new Date(endDateTime).getTime() / milliseconds) - 1;
            return formattedData.filter((datum) => {
                const timestamp = Math.floor(new Date(datum.timestamp).getTime() / milliseconds);
                const isDatumPresent = timestamp >= fromDateTime && timestamp <= toDateTime;
                if (isDatumPresent) {
                    console.info('PRESENT', datum.timestamp, 'TIMESTAMP', timestamp);
                }
                return isDatumPresent;
            });
        }
        catch (e) {
            console.error('ERROR while computing timestamp', e);
            return [];
        }
    }
    listEntity(params) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.remotes.ruleSimulator.getURL(params).then((url) => __awaiter(this, void 0, void 0, function* () {
                return yield this.remotes.ruleSimulator.downloadLogs(url.presigned_url);
            }));
        });
    }
    extractFilters(any) {
        return [];
    }
}
