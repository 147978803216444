import { Box, Icon } from "@cloudscape-design/components-themed/components";
import React from "react";
import { ReactComponent as AwsProvider } from '@/presentation/assets/img/icons/aws-icon.svg';
import { ReactComponent as GcpProvider } from '@/presentation/assets/img/icons/gcp-icon.svg';
import { ReactComponent as AzureProvider } from '@/presentation/assets/img/icons/azure-icon.svg';
import { ReactComponent as GithubIcon } from "@/presentation/assets/img/icons/github-icon.svg";
import { ReactComponent as MicrosoftGraphIcon } from "@/presentation/assets/img/icons/microsoft-graph-icon.svg";
import { ReactComponent as DefaultProvider } from "@/presentation/assets/img/icons/default-cloud-provider-icon.svg";
import { useId } from "@/presentation/components/commons/use-id";
const ProvidersSwitchLogo = (props) => {
    const getProviderLogo = (type) => {
        switch (type) {
            case 'AWS':
                return AwsProvider;
            case 'GCP':
                return GcpProvider;
            case 'AZURE':
                return AzureProvider;
            case 'GITHUB':
                return GithubIcon;
            case 'MICROSOFT-GRAPH':
                return MicrosoftGraphIcon;
            default:
                return DefaultProvider;
        }
    };
    return React.createElement(React.Fragment, null,
        React.createElement(Box, { key: useId(), margin: { bottom: 'l' } },
            React.createElement(Icon, { key: useId(), size: "large", svg: getProviderLogo(props.providerType) })));
};
export default ProvidersSwitchLogo;
