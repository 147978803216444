import moment from 'moment';
import * as isCidr from 'is-cidr';
import { WILDCARD, DATE_DISPLAY_FORMATS } from '@/presentation/common-utils/constants';
import { GetDocsToken } from "@/presentation/storage/misc-storage";
import { DOCU_KIVERA_REDIRECTION } from "@/presentation/storage";
export const STRING_UNKNOWN = 'unknown';
export const STRING_POLICIES = 'policies';
export const STRING_POLICY = 'policy';
export const STRING_RULES = 'rules';
export const STRING_RULE = 'rule';
export const STRING_PROFILES = 'profiles';
export const STRING_PROFILE = 'profile';
export const STRING_APPLICATIONS = 'applications';
export const STRING_APPLICATION = 'application';
export const STRING_PROXIES = 'proxies';
export const STRING_PROXY = 'proxy';
export const STRING_IDENTITY = 'identity';
export const STRING_TRAILBLAZER = 'trailblazer';
export const TIME_30_MINS_AGO = 0.5 * 60 * 1000;
const pluralToSingle = {
    policies: STRING_POLICY,
    rules: STRING_RULE,
    profiles: STRING_PROFILE,
    applications: STRING_APPLICATION,
    proxies: STRING_PROXY,
    identities: STRING_IDENTITY,
    trailblazers: STRING_TRAILBLAZER
};
const TRAIL_BLAZER_LAUNCH_URl = 'https://console.aws.amazon.com/cloudformation/home?#/stacks/quickcreate?templateUrl=https%3A%2F%2Fdl.kivera.io.s3.amazonaws.com%2Ftemplates%2Ftrailblazer%2Ftrailblazer-latest.yaml&stackName=TrailBlazer&param_TrailBlazerVersion=latest';
export function singularize(plural) {
    return pluralToSingle[plural] ? pluralToSingle[plural] : STRING_UNKNOWN;
}
export function isOnline(timeString) {
    const now = moment();
    const previousTime = moment.utc(timeString);
    if (!previousTime.isValid())
        return false;
    return now.diff(previousTime) < TIME_30_MINS_AGO;
}
export function booleanToString(value) {
    return value ? 'true' : 'false';
}
export function booleanToStringFirstUpper(value) {
    return value ? 'True' : 'False';
}
export function booleanToOnline(value) {
    return value ? 'online' : 'offline';
}
export function stringToBoolean(value) {
    return value === 'true';
}
export function formatOrganizationPolicyFunction(funcs) {
    let globalPolicy = '\r\n';
    const data = funcs.OrganizationPolicyFunctions;
    if (data && data.length > 0) {
        data.forEach((item) => {
            let str = item.function.replace(/\\n/g, '\n');
            str = str.replace(/\\r/g, '\r');
            str = str.replace(/\\"/g, '"');
            globalPolicy += str + '\r\n\n';
        });
    }
    return globalPolicy;
}
function removeShake(elementID) {
    console.log('removing', elementID);
    const el = document.getElementById(elementID);
    if (el && el.classList.contains('shake-shake')) {
        console.log('removing shake');
        el.classList.remove('shake-shake');
    }
}
export function shakeInvalidInput(elementID) {
    console.log('setting', elementID);
    const timeout = setTimeout(removeShake, 1000, elementID);
    // setTimeout(() => {clearTimeout(timeout)}, 1000, elementID)
    return 'shake-shake';
}
export function stringTruncate(str, length, dotLength = 3, dotCharacter = '.') {
    if (str.length > length) {
        return String(str.substring(0, length - dotLength)) + dotCharacter.repeat(dotLength);
    }
    else {
        return str;
    }
}
export function isAlphaNumeric(text, additionalCharacters) {
    let code;
    const additionalCodes = [];
    additionalCharacters === null || additionalCharacters === void 0 ? void 0 : additionalCharacters.map(char => additionalCodes.push(char.charCodeAt(0)));
    for (let i = 0; i < text.length; i++) {
        code = text.charCodeAt(i);
        if (!(code > 47 && code < 58) && // numeric (0-9)
            !(code > 64 && code < 91) && // upper alpha (A-Z)
            !(code > 96 && code < 123) && // lower alpha (a-z)
            !(additionalCodes.includes(code))) { // additional characters
            return false;
        }
    }
    return true;
}
export function getWildcardCountFromArray(arr) {
    if (arr.length > 0) {
        return arr.filter(ele => ele === WILDCARD).length;
    }
    return 0;
}
export function isResourceNameValid(text, additionalCharacters) {
    return isAlphaNumeric(text, ['-']) &&
        !text.startsWith('-') && !text.endsWith('-') && !text.includes('--') && !text.includes(' ');
}
export function isResourceDescValid(text, additionalCharacters) {
    return text.trim().length > 0;
}
export function isAttributeKeyValid(text) {
    return isAlphaNumeric(text, ['_', '.', ':', '/', '=', '+', '@', '-', ' ']) &&
        !text.startsWith('-') && !text.endsWith('-') && !text.includes('--');
}
export function isAttributeKeyValidDomainName(text) {
    var _a;
    return ((_a = (text.match(/^.?(?:[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?\.)+[a-z0-9][a-z0-9-]{0,61}[a-z0-9]$/) || [])) === null || _a === void 0 ? void 0 : _a.length) === 1;
}
export const isValidJsonPath = (text) => {
    var _a;
    return ((_a = (text.match(/^(?:\$|\w+)?(?:\.\w+|\[\-?\d+\]|\[\'(?:[^'\\]|\\.)*\'\]|\[\"(?:[^"\\]|\\.)*\"\])*$/g) || [])) === null || _a === void 0 ? void 0 : _a.length) === 1;
};
export function isValidEmail(email) {
    var _a;
    // Regex extracted from World HTML5 Specification
    // https://html.spec.whatwg.org/multipage/input.html#valid-e-mail-address
    // It has been tested for DDoS Safety from here: https://devina.io/redos-checker
    return ((_a = (email.match(/^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/g) || [])) === null || _a === void 0 ? void 0 : _a.length) === 1;
}
export function isValidCIDR(addresses) {
    const splitted = addresses.split(',');
    for (let i = 0; i < splitted.length; i++) {
        if (isCidr.default(splitted[i]) === 0) {
            return false;
        }
    }
    return true;
}
export function isUniqueArray(array) {
    // when copying elements into Set, it removes all duplicates
    const set = new Set(array);
    return array.length !== set.size;
}
export function isValidURL(url) {
    return url.match(/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g) !== null;
}
export function goToListPage(history) {
    const splitted = history.location.pathname.split('/');
    if (splitted[1]) {
        history.push('/' + splitted[1]);
    }
}
export function launchTrailBlazer() {
    const trailBlazerInfo = JSON.parse(GetDocsToken(DOCU_KIVERA_REDIRECTION));
    const trailBlazerURL = trailBlazerInfo.url + "/docs/trailblazer-setup?auth_token" + trailBlazerInfo.token;
    openLinkInNewTab(trailBlazerURL);
}
export function openLinkInNewTab(link) {
    return window.open(link, '_blank');
}
export const areCombosValid = (invalidCombos, array) => {
    let valid = true;
    for (let i = 0; i < invalidCombos.length; i++) {
        const combo = invalidCombos[i];
        // if every element in the invalid combo is present in the original array
        // then it is considered invalid
        if (combo.every(item => array.includes(item))) {
            // even if one invalid combo is present
            // stop proceeding further
            valid = false;
            break;
        }
    }
    return valid;
};
export const getContainerHeight = (documentId, callbackFunc, currentHeight) => {
    const domRect = document.getElementById(documentId).getBoundingClientRect();
    // Approximate padding size around the container in pixels
    const approximatePaddings = 24;
    const caluclatedHeight = window.innerHeight - (domRect.top + approximatePaddings);
    if (caluclatedHeight > currentHeight) {
        callbackFunc(caluclatedHeight);
    }
};
export const getLocaleTimestamp = (dateTimeString, dateFormat) => {
    if (dateTimeString) {
        const userLocale = navigator.languages && navigator.languages.length
            ? navigator.languages[0]
            : navigator.language;
        const formattedDate = moment.utc(dateTimeString).local().locale(userLocale).format();
        const convertToLocale = new Date(formattedDate);
        if (dateFormat === DATE_DISPLAY_FORMATS.DATE_ONLY) {
            return convertToLocale.toLocaleDateString(userLocale);
        }
        else {
            return convertToLocale.toLocaleString(userLocale);
        }
    }
    return '-';
};
export const getUrlTabbedId = (data) => {
    const windowLocationSearch = decodeURIComponent(window.location.search);
    const searchParams = new URLSearchParams(windowLocationSearch);
    if (searchParams.has("tabId")) {
        const tabId = searchParams.get('tabId');
        return (tabId && data.includes(tabId)) ? tabId : undefined;
    }
    return undefined;
};
export const getHashedTabbedId = (data) => {
    var _a;
    const getWindowHash = (_a = window.location.hash) === null || _a === void 0 ? void 0 : _a.substring(1);
    return ((getWindowHash === null || getWindowHash === void 0 ? void 0 : getWindowHash.length) > 0 && data.includes(getWindowHash)) ? getWindowHash : undefined;
};
export const uniqueAndSortedArrayOfObjectFilters = (filtersArray, propertyKey) => {
    var _a, _b;
    const uniqueFilteredArray = (_b = (_a = [...new Set(filtersArray)]) === null || _a === void 0 ? void 0 : _a.filter(item => !!item)) === null || _b === void 0 ? void 0 : _b.sort((a, b) => a > b ? 1 : -1);
    const uniqueObjects = (uniqueFilteredArray === null || uniqueFilteredArray === void 0 ? void 0 : uniqueFilteredArray.map(item => {
        return {
            propertyKey,
            value: item
        };
    })) || [];
    return uniqueObjects;
};
export const getUrlParamsName = (name) => {
    const windowLocationSearch = decodeURIComponent(window.location.search);
    const searchParams = new URLSearchParams(windowLocationSearch);
    return searchParams.get(name);
};
export const sortArrayOfObjectsByProperty = (arr, propertyExtractor) => {
    const sorted = arr === null || arr === void 0 ? void 0 : arr.sort((a, b) => {
        const x = propertyExtractor === null || propertyExtractor === void 0 ? void 0 : propertyExtractor(a);
        const y = propertyExtractor === null || propertyExtractor === void 0 ? void 0 : propertyExtractor(b);
        if (x > y) {
            return 1;
        }
        if (x < y) {
            return -1;
        }
        return 0;
    });
    return sorted !== null && sorted !== void 0 ? sorted : [];
};
export const fetchUniquePropertyFilters = (data) => {
    var _a;
    const uniqueFilters = [];
    (_a = data === null || data === void 0 ? void 0 : data.tokens) === null || _a === void 0 ? void 0 : _a.reduce((acc, obj) => {
        const { propertyKey, operator, value } = obj;
        const keyOpValue = `${propertyKey}-${operator}-${value}-`;
        if (!acc.includes(keyOpValue)) {
            acc.push(keyOpValue);
            uniqueFilters.push(obj);
        }
        return acc;
    }, []);
    return Object.assign(Object.assign({}, data), { tokens: uniqueFilters });
};
export const makeInvalidFormErrorMessage = (entity, crud) => {
    return `Failed to ${crud} ${entity}. Please review and correct the highlighted errors and try again.`;
};
export const getPropertyFilteringOptions = (filterData, type) => {
    if (!filterData || filterData.length === 0)
        return []; // Early return if data is empty or null
    // Sort the data first, then get unique values using Set
    const uniqueData = [...new Set(filterData.sort())];
    // Map over the unique data to create the filtering options
    return uniqueData.map((filterValue) => ({
        propertyKey: type,
        value: filterValue,
    }));
};
