import { addColumnSortLabels } from '@/presentation/components/commons/labels';
import React from 'react';
import { PATHS, PATHS_SINGULAR, PATH_PROFILES } from '@/presentation/common-utils/constants';
import RegularLink from '@/presentation/components/links/regular-link';
import { sortingComparatorByKey } from '@/lib/misc/utils';
import ExternalLink from '@/presentation/components/links/external-link';
import { descriptionFilteringProperty, idFilteringProperty, nameFilteringProperty, numberOfRulesFilteringProperty } from '@/presentation/pages/common-config';
const identitiesProfileCommonColumns = [
    {
        id: 'name',
        sortingField: 'name',
        header: 'Name',
        cell: (item) => (item === null || item === void 0 ? void 0 : item.name) || '-',
    },
    {
        id: 'description',
        sortingField: 'description',
        header: 'Description',
        cell: (item) => (item === null || item === void 0 ? void 0 : item.description) || '-',
    },
    {
        id: 'numberOfRules',
        header: 'Attached Rules (#)',
        cell: (item) => item === null || item === void 0 ? void 0 : item.numberOfRules,
        sortingField: 'numberOfRules',
        sortingComparator: (a, b) => sortingComparatorByKey(a, b, 'numberOfRules')
    }
];
export const IdentitiesProfilesColumnDefinitions = (incomingPath = "", withPreviousPath = false) => {
    return addColumnSortLabels([
        {
            id: 'id',
            sortingField: 'id',
            header: 'ID',
            cell: (item) => {
                let link = `${PATHS.PROFILES}/${item.id}`;
                if (withPreviousPath) {
                    // /proxies/:id/identity/:identitiesID/profile/:profileID
                    link = `${incomingPath + PATHS_SINGULAR.PROFILE}/${item.id}`;
                    return React.createElement(RegularLink, { to: link, label: item.id });
                }
                return React.createElement(ExternalLink, { to: link, label: item.id });
            },
            width: 120
        },
        ...identitiesProfileCommonColumns
    ]);
};
export const IdentitiesProfilesFormColumnDefinitions = addColumnSortLabels([
    {
        id: 'id',
        sortingField: 'id',
        header: 'ID',
        cell: item => {
            const link = `${PATH_PROFILES.VIEW}/${item.id}`;
            return React.createElement(ExternalLink, { to: link, label: item.id });
        },
        width: 120
    },
    ...identitiesProfileCommonColumns
]);
export const IdentitiesProfilesDetailsFilteringProperties = [
    idFilteringProperty,
    nameFilteringProperty,
    descriptionFilteringProperty,
    numberOfRulesFilteringProperty
];
export const IdentitiesProfilesDetailsTablePreferences = {
    pageSize: 10,
    visibleContent: ['checkbox', 'id', 'name', 'description', 'numberOfRules'],
    wrapLines: false,
    custom: {
        autoResize: true
    }
};
export const IdentitiesProfilesDetailsTableVisibleContentOptions = [
    {
        label: null,
        options: [
            { id: 'id', label: 'ID', editable: false },
            { id: 'name', label: 'Name', editable: false },
            { id: 'description', label: 'Description', editable: false },
            { id: 'numberOfRules', label: 'Attached Rules (#)', editable: true }
        ]
    }
];
export const IdentitiesProfilesDetailsTablePageSizeOptions = [
    { value: 10, label: '10 Profiles' },
    { value: 30, label: '30 Profiles' },
    { value: 50, label: '50 Profiles' }
];
export const IDENTITIES_VIEW_CIDR_RANGES_COLUMN_DEFINITIONS = [
    {
        id: 'range',
        header: 'CIDR Ranges',
        cell: item => { var _a; return (_a = item === null || item === void 0 ? void 0 : item.range) !== null && _a !== void 0 ? _a : ''; },
        sortingField: 'range',
    }
];
export const IDENTITIES_VIEW_CLOUD_TENANTS_COLUMN_DEFINITIONS = [
    {
        id: 'id',
        header: 'ID',
        cell: item => { var _a; return (_a = item === null || item === void 0 ? void 0 : item.id) !== null && _a !== void 0 ? _a : '-'; },
        sortingField: 'id',
    },
    {
        id: 'path',
        header: 'Path',
        cell: item => { var _a; return (_a = item === null || item === void 0 ? void 0 : item.path) !== null && _a !== void 0 ? _a : '-'; },
        sortingField: 'path',
    },
    {
        id: 'name',
        header: 'Name',
        cell: item => { var _a; return (_a = item === null || item === void 0 ? void 0 : item.name) !== null && _a !== void 0 ? _a : '-'; },
        sortingField: 'name',
    }
];
export const IDENTITIES_VIEW_USER_IDENTITIES_COLUMN_DEFINITIONS = [
    {
        id: 'attribute',
        header: React.createElement("div", { className: "table-header-padding" }, "Attribute"),
        cell: item => { var _a; return React.createElement("div", { className: "table-header-padding" }, (_a = item === null || item === void 0 ? void 0 : item.attribute) !== null && _a !== void 0 ? _a : ''); },
        sortingField: 'attribute',
    },
    {
        id: 'value',
        header: React.createElement("div", { className: "table-header-padding" }, "Value"),
        cell: item => { var _a; return React.createElement("div", { className: "table-header-padding" }, (_a = item === null || item === void 0 ? void 0 : item.value) !== null && _a !== void 0 ? _a : ''); },
        sortingField: 'value',
    }
];
