import React, { useContext, useEffect, useState } from 'react';
import { useIsMounted } from '@/presentation/hooks';
const defaultNotificationsContext = {
    notifications: [],
    pushNotifications: undefined,
    dismissNotification: undefined
};
export const NotificationsContext = React.createContext(defaultNotificationsContext);
export const useNotifications = () => useContext(NotificationsContext);
export const NotificationsProvider = (props) => {
    const [notifications, setNotifications] = useState([]);
    const isMounted = useIsMounted();
    const dismissNotification = (id) => {
        const allNotifications = props.handler.getNotifications();
        const filtered = allNotifications.filter(item => {
            return (item === null || item === void 0 ? void 0 : item.id) !== id;
        });
        setNotifications(filtered);
    };
    const pushNotifications = (definition) => {
        const appended = [...notifications, definition];
        setNotifications(appended);
        window.scrollTo(0, 0);
        // Auto dismiss notifications after 5 secs
        setTimeout(() => {
            if (isMounted.current) {
                dismissNotification(definition.id);
            }
        }, 5000);
    };
    useEffect(() => {
        props.handler.setNotifications(notifications);
    }, [notifications]);
    return React.createElement(NotificationsContext.Provider, { value: {
            notifications,
            pushNotifications,
            dismissNotification
        } }, props.children);
};
