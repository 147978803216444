import React, { useEffect, useState } from 'react';
import { paginationLabels } from '@/presentation/components/commons/labels';
import { useAuth0 } from '@/lib/auth0';
import { COLUMN_WIDTH_STORAGE_KEY, PREFERENCES_STORAGE_KEY } from '@/presentation/pages/constants-and-types';
import ContentWrapper from '@/presentation/components/content-wrapper';
import { GetDateRangeChangeDetail, GetDateTimeOptions, InitialDateRangeOptions, SetDateRangeChangeDetail, SetDateTimeOptions } from '@/presentation/storage';
import { isEmptyObject } from '@/lib/misc/utils';
import { useColumnWidths } from '@/presentation/components/commons/use-column-widths';
import { useLocalStorage } from '@/presentation/components/commons/localStorage';
import { useCollection } from '@cloudscape-design/collection-hooks';
import { TableHeader, TableNoMatchState } from '@/presentation/components/commons/common-components';
import { makeHierarchicalBreadcrumbsFromURLPath } from '@/presentation/components/commons/breadcrumbs';
import { KiverascapeLayout } from '@/presentation/layouts/kiverascape-layout';
import { useSplitPanel } from '@/presentation/components/commons/use-split-panel';
import { getDateTimeRangeOptions, makeDateRangeI18nStrings, makeDateRangeRelativeOptions, makePropertyFilterI18nStrings, validateDateRange } from '@/presentation/pages';
import { getRulesSimulatorListSplitPanel } from '@/presentation/pages/rules-simulator/rules-simulator-list-split-panel';
import { RulesSimulatorListCardsColumnDefinitions, RulesSimulatorListFilteringOptions, RulesSimulatorListFilteringProperties, RulesSimulatorListTablePageSizeOptions, RulesSimulatorListTablePreferences, RulesSimulatorListTableVisibleContentOptions, RulesSimulatorDefaultDateRangeFilter, RulesSimulatorDefaultDatetimeOptions, RulesSimulatorInitialTableOptions } from '@/presentation/pages/rules-simulator/rules-simulator-list-config';
import { RULES_SIMULATOR_LIST_SPLIT_PANEL_I18NSTRINGS } from '@/presentation/pages/rules-simulator/rules-simulator-list-split-panel-config';
import { useRuleSimulatorHandlers } from '@/presentation/providers/rule-simulator-handlers-provider';
import { useLocation } from "react-router-dom";
import { Box, Table, Button, CollectionPreferences, DateRangePicker, Pagination, PropertyFilter, SpaceBetween, SplitPanel } from '@cloudscape-design/components-themed/components';
import { getFilterCounterText } from '@/presentation/components/commons/tableCounterStrings';
import TableContainer from '@/presentation/components/table/table-container';
import { useIsMounted } from '@/presentation/hooks';
import { fetchUniquePropertyFilters } from '@/presentation/common-utils/common-utils';
const RulesSimulatorEmptyState = () => {
    return React.createElement(Box, { margin: { vertical: 'xs' }, textAlign: "center", color: "inherit" },
        React.createElement(SpaceBetween, { size: "xxs" },
            React.createElement(Box, null, "No simulator logs found. Please create a proxy and send some traffic to see logs.")));
};
const RulesSimulatorList = (props) => {
    const { user } = useAuth0();
    const location = useLocation();
    const breadCrumbs = makeHierarchicalBreadcrumbsFromURLPath(location === null || location === void 0 ? void 0 : location.pathname);
    const [loading, setLoading] = useState(false);
    const [simulatorData, setSimulatorData] = useState([]);
    const [splitKeyValue, setSplitKeyValue] = useState(1);
    const { ruleSimulator } = useRuleSimulatorHandlers();
    const ruleSimulatorHandler = ruleSimulator;
    const [ruleID, setRuleID] = useState(props.ruleID);
    const [providerServicesFilteringOptions, setProviderServicesFilteringOptions] = useState(RulesSimulatorListFilteringOptions);
    const updatedColumnDefinitions = [...RulesSimulatorListCardsColumnDefinitions,
        {
            id: 'table-actions',
            header: '',
            cell: (item) => {
                return (React.createElement(React.Fragment, null,
                    React.createElement(Button, { key: "inspect-button", variant: "inline-icon", iconName: "search", ariaLabel: 'inspect-button', onClick: () => {
                            setSelectedItems([item]);
                            setSplitKeyValue(splitKeyValue + 1);
                            setSplitOpen(true);
                        } })));
            }
        }
    ];
    const [columnDefinitions] = useColumnWidths(COLUMN_WIDTH_STORAGE_KEY.RULES_SIMULATOR_LIST, updatedColumnDefinitions);
    const [preferences, setPreferences] = useLocalStorage(PREFERENCES_STORAGE_KEY.RULES_SIMULATOR_LIST, RulesSimulatorListTablePreferences);
    const { items, actions, filteredItemsCount, collectionProps, propertyFilterProps, paginationProps } = useCollection(simulatorData, {
        propertyFiltering: {
            filteringProperties: RulesSimulatorListFilteringProperties,
            empty: React.createElement(RulesSimulatorEmptyState, null),
            noMatch: React.createElement(TableNoMatchState, { resourceName: 'simulator logs' })
        },
        pagination: { pageSize: preferences.pageSize },
        sorting: { defaultState: { sortingColumn: updatedColumnDefinitions === null || updatedColumnDefinitions === void 0 ? void 0 : updatedColumnDefinitions[1], isDescending: true } },
        selection: {}
    });
    const [renderCount, setRenderCount] = useState(1);
    const [panelPreferences, setPanelPreferences] = useState({ position: 'side' });
    const [splitOpen, setSplitOpen] = useState(false);
    const onSplitPanelToggle = ({ detail: { open } }) => {
        setSplitKeyValue(splitKeyValue + 1);
        setSplitOpen(open);
    };
    const [selectedItems, setSelectedItems] = useState([]);
    const { header: panelHeader, body: panelBody } = getRulesSimulatorListSplitPanel(selectedItems);
    const { splitPanelSize, onSplitPanelResize } = useSplitPanel(collectionProps.selectedItems, splitOpen, onSplitPanelToggle);
    const isMounted = useIsMounted();
    const [dateTimeOptions, setDateTimeOptions] = useState(GetDateTimeOptions(RulesSimulatorInitialTableOptions.dateTimeOptionsStorage, RulesSimulatorDefaultDatetimeOptions));
    const [dateRangeFilter, setDateRangeFilter] = useState(GetDateRangeChangeDetail(RulesSimulatorInitialTableOptions.dateRangeStorage, RulesSimulatorDefaultDateRangeFilter).value);
    const refreshList = (ruleID, sFilters) => {
        if (isMounted.current) {
            setLoading(true);
        }
        if (ruleID !== 0) {
            const listParams = Object.assign({ start_timestamp: dateTimeOptions.startDateTime, end_timestamp: dateTimeOptions.endDateTime, from: 0, size: 1000, rule_id: ruleID }, (sFilters && !isEmptyObject(sFilters) && { filter: sFilters }));
            ruleSimulatorHandler === null || ruleSimulatorHandler === void 0 ? void 0 : ruleSimulatorHandler.listEntity(listParams).then((entityItems) => {
                const formattedItems = ruleSimulatorHandler === null || ruleSimulatorHandler === void 0 ? void 0 : ruleSimulatorHandler.getFormattedEntities(String(ruleID), entityItems);
                const { rulesSimulationList = [], servicesFilteringOptions = [], providersFilteringOptions = [] } = (formattedItems === null || formattedItems === void 0 ? void 0 : formattedItems[0]) || {};
                if (isMounted.current) {
                    setSimulatorData(rulesSimulationList);
                    setProviderServicesFilteringOptions([...providerServicesFilteringOptions, ...providersFilteringOptions, ...servicesFilteringOptions]);
                }
                //  Commenting these out to be opened again in near future
                // actions.setPropertyFiltering({ tokens: [{
                //         operator: '=',
                //         propertyKey: 'ruleID',
                //         value: String(ruleID)
                //     }], operation: 'and' });
            }).catch((e) => {
                // handle no data returned, as request might be cancelled
                setSimulatorData([]);
            }).finally(() => {
                if (isMounted.current) {
                    setLoading(false);
                }
            });
        }
        else {
            if (isMounted.current) {
                actions.setPropertyFiltering({ tokens: [], operation: 'and' });
                setLoading(false);
            }
        }
    };
    useEffect(() => {
        refreshList(ruleID);
    }, [user]);
    const dateRangeOnChange = (detail) => {
        const dateTimeOptions = getDateTimeRangeOptions(detail);
        if (detail === null || detail === void 0 ? void 0 : detail.value) {
            SetDateRangeChangeDetail(RulesSimulatorInitialTableOptions.dateRangeStorage, detail);
        }
        else {
            SetDateRangeChangeDetail(RulesSimulatorInitialTableOptions.dateRangeStorage, InitialDateRangeOptions);
        }
        const dateTimeValue = (detail === null || detail === void 0 ? void 0 : detail.value) || (RulesSimulatorDefaultDateRangeFilter === null || RulesSimulatorDefaultDateRangeFilter === void 0 ? void 0 : RulesSimulatorDefaultDateRangeFilter.value);
        setDateRangeFilter(dateTimeValue);
        setDateTimeOptions(dateTimeOptions);
        SetDateTimeOptions(RulesSimulatorInitialTableOptions.dateTimeOptionsStorage, dateTimeOptions);
    };
    const handleOnChangePanelPreferences = (preference) => {
        if (preference === null || preference === void 0 ? void 0 : preference.detail) {
            setPanelPreferences(preference.detail);
        }
    };
    const handlePropertyFiltersOnChange = (event) => {
        const detail = event === null || event === void 0 ? void 0 : event.detail;
        let isValidQuery = true;
        detail.tokens.map(token => {
            if (token.propertyKey === 'ruleID') {
                const parsedRuleID = parseInt(token.value, 10);
                isValidQuery = !!parsedRuleID;
                if (isValidQuery) {
                    setRuleID(parsedRuleID);
                    refreshList(parsedRuleID);
                }
            }
            else {
                isValidQuery = !!token.value;
            }
        });
        if (isValidQuery) {
            const filteredDetail = fetchUniquePropertyFilters(detail);
            actions.setPropertyFiltering(filteredDetail);
        }
    };
    const handleSplitPanelResize = (evt) => {
        var _a;
        setSplitKeyValue(splitKeyValue + (((_a = evt === null || evt === void 0 ? void 0 : evt.detail) === null || _a === void 0 ? void 0 : _a.size) || 1));
        onSplitPanelResize(evt);
    };
    return React.createElement(React.Fragment, null,
        React.createElement(KiverascapeLayout, { breadcrumbMenu: breadCrumbs, splitPanelOpen: splitOpen, onSplitPanelToggle: onSplitPanelToggle, splitPanelSize: splitPanelSize, onSplitPanelResize: handleSplitPanelResize, splitPanelPreferences: panelPreferences, onSplitPanelPreferencesChange: handleOnChangePanelPreferences, splitPanel: panelBody && selectedItems.length > 0 &&
                (React.createElement(SplitPanel, { header: panelHeader, i18nStrings: RULES_SIMULATOR_LIST_SPLIT_PANEL_I18NSTRINGS }, panelBody)) || null, content: React.createElement(ContentWrapper, { testid: "rule-simulator-list-items", page: "simulator" },
                React.createElement(TableContainer, { key: renderCount },
                    React.createElement(Table, Object.assign({}, collectionProps, { key: splitKeyValue, resizableColumns: true, loading: loading, loadingText: 'Loading Logs...', columnDefinitions: columnDefinitions, visibleColumns: preferences.visibleContent, items: items, ariaLabels: {}, variant: "full-page", wrapLines: preferences.wrapLines, selectedItems: selectedItems, onSelectionChange: event => {
                            setSelectedItems(event.detail.selectedItems);
                            setSplitKeyValue(splitKeyValue + 1);
                            setSplitOpen(true);
                        }, header: React.createElement(TableHeader, Object.assign({ variant: 'awsui-h1-sticky', title: 'Simulator' }, props)), filter: React.createElement(React.Fragment, null,
                            React.createElement(SpaceBetween, { size: 'l', direction: 'horizontal' },
                                React.createElement("div", { className: 'list-property-filter' },
                                    React.createElement(PropertyFilter, Object.assign({}, propertyFilterProps, { i18nStrings: makePropertyFilterI18nStrings({ filteringPlaceholder: 'Filter Logs' }), countText: getFilterCounterText(filteredItemsCount), expandToViewport: true, filteringOptions: providerServicesFilteringOptions, filteringProperties: RulesSimulatorListFilteringProperties, onChange: handlePropertyFiltersOnChange }))),
                                React.createElement(DateRangePicker, { onChange: ({ detail }) => {
                                        dateRangeOnChange(detail);
                                    }, value: dateRangeFilter, relativeOptions: makeDateRangeRelativeOptions(), isValidRange: validateDateRange, i18nStrings: makeDateRangeI18nStrings(), placeholder: "Search by date and time range", showClearButton: true }),
                                React.createElement(Button, { variant: "primary", onClick: () => refreshList(ruleID) }, "Search"))), pagination: React.createElement(Pagination, Object.assign({}, paginationProps, { ariaLabels: paginationLabels })), preferences: React.createElement(CollectionPreferences, { title: "Preferences", confirmLabel: "Confirm", cancelLabel: "Cancel", disabled: false, preferences: preferences, onConfirm: ({ detail }) => {
                                setPreferences(detail);
                                setRenderCount(renderCount + 1);
                            }, pageSizePreference: {
                                title: 'Page Size',
                                options: RulesSimulatorListTablePageSizeOptions
                            }, wrapLinesPreference: {
                                label: 'Wrap Lines',
                                description: 'If checked, lines will be wrapped to display all of the text'
                            }, visibleContentPreference: {
                                title: 'Visible Columns',
                                options: RulesSimulatorListTableVisibleContentOptions
                            } }) })))), contentType: "default" }));
};
export default RulesSimulatorList;
