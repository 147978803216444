import { addColumnSortLabels } from '@/presentation/components/commons/labels';
import React from 'react';
import { Badge } from '@cloudscape-design/components-themed/components';
import { ServiceInspections } from '@/presentation/handlers';
import { getLocaleTimestamp } from '@/presentation/common-utils/common-utils';
import { descriptionFilteringProperty, providerColumnDefinition, providerFilteringProperty, serviceFilteringProperty } from '@/presentation/pages/common-config';
import { DATE_DISPLAY_FORMATS } from '@/presentation/common-utils/constants';
import { capitalizeFirstForcefully } from '@/lib/misc/utils';
export const GlobalServicesListFilteringProperties = [
    providerFilteringProperty,
    serviceFilteringProperty,
    {
        key: 'title',
        operators: [':', '!:', '=', '!='],
        propertyLabel: 'Title',
        groupValuesLabel: 'Title values'
    },
    descriptionFilteringProperty,
    {
        key: 'status',
        operators: ['=', '!='],
        propertyLabel: 'Status',
        groupValuesLabel: 'Status values'
    },
];
export const GlobalServicesListFilteringOptions = [
    {
        propertyKey: 'status',
        value: capitalizeFirstForcefully(ServiceInspections.ENABLED)
    },
    {
        propertyKey: 'status',
        value: capitalizeFirstForcefully(ServiceInspections.DISABLED)
    },
];
export const GlobalServicesListColumnDefinitions = addColumnSortLabels([
    providerColumnDefinition,
    {
        id: 'service',
        sortingField: 'service',
        header: 'Service',
        cell: (item) => {
            return (item === null || item === void 0 ? void 0 : item.service) || '-';
        }
    },
    {
        id: 'title',
        sortingField: 'title',
        header: 'Title',
        cell: (item) => {
            return (item === null || item === void 0 ? void 0 : item.title) || '-';
        }
    },
    {
        id: 'description',
        sortingField: 'description',
        header: 'Description',
        cell: (item) => {
            return (item === null || item === void 0 ? void 0 : item.description) || '-';
        }
    },
    {
        id: 'timestamp',
        sortingField: 'created_at',
        header: 'Created',
        cell: (item) => {
            return getLocaleTimestamp(item === null || item === void 0 ? void 0 : item.created_at, DATE_DISPLAY_FORMATS.DATE_ONLY);
        }
    },
    {
        id: 'status',
        sortingField: 'status',
        header: 'Status',
        cell: (item) => {
            if (item === null || item === void 0 ? void 0 : item.inspection) {
                let inspectionColor = 'grey';
                if (String(item === null || item === void 0 ? void 0 : item.inspection).toLowerCase() === ServiceInspections.ENABLED) {
                    inspectionColor = 'green';
                }
                else if (String(item === null || item === void 0 ? void 0 : item.inspection).toLowerCase() === ServiceInspections.DISABLED) {
                    inspectionColor = 'red';
                }
                return React.createElement(React.Fragment, null,
                    React.createElement(Badge, { color: inspectionColor }, item === null || item === void 0 ? void 0 : item.inspection));
            }
            return '-';
        }
    }
]);
export const GlobalServicesListTablePreferences = {
    pageSize: 30,
    visibleContent: ['provider', 'service', 'title', 'description', 'timestamp', 'status'],
    wrapLines: false,
    custom: {
        autoResize: true
    }
};
export const GlobalServicesListTableVisibleContentOptions = [
    {
        label: null,
        options: [
            { id: 'provider', label: 'Provider', editable: false },
            { id: 'service', label: 'Service', editable: true },
            { id: 'title', label: 'Title', editable: true },
            { id: 'description', label: 'Description', editable: true },
            { id: 'timestamp', label: 'Created', editable: true },
            { id: 'status', label: 'Status', editable: true },
        ]
    }
];
export const GlobalServicesListTablePageSizeOptions = [
    { value: 10, label: '10 Services' },
    { value: 30, label: '30 Services' },
    { value: 50, label: '50 Services' }
];
