import React from 'react';
import GCPSVGIconUrl from '@/presentation/assets/img/icons/gcp-icon.svg';
import AWSSVGIconUrl from '@/presentation/assets/img/icons/aws-icon.svg';
import AzureSVGIconUrl from '@/presentation/assets/img/icons/azure-icon.svg';
import GithubSVGIconUrl from '@/presentation/assets/img/icons/github-icon.svg';
import MicrosoftGraphSVGIconUrl from '@/presentation/assets/img/icons/microsoft-graph-icon.svg';
import OpenAIIconSVGUrl from '@/presentation/assets/img/icons/openai-icon.svg';
import ElasticCloudIconSVGUrl from '@/presentation/assets/img/icons/elastic-cloud-icon.svg';
import DefaultProviderSVGUrl from '@/presentation/assets/img/icons/default-cloud-provider-icon.svg';
import { Badge, Toggle, } from '@cloudscape-design/components-themed/components';
import { capitalizeFirst, getRandomUUID, toPascalCase } from '@/lib/misc/utils';
import { ServiceInspections } from '@/presentation/handlers';
import { DomainACL } from '@/domain/models';
import { useLocation } from 'react-router-dom';
import { PROPERTY_FILTER_OPERATOR_VALUES } from '../common-utils/constants';
import ErrorPageWrapper from './errors/page-wrapper';
var ACLAction = DomainACL.ACLAction;
export const getLogo = (provider, size = 'normal') => {
    switch (provider) {
        case 'AWS':
            return React.createElement(Badge, { color: "green" }, "AWS");
        case 'GCP':
            return React.createElement(Badge, { color: "grey" }, "GCP");
        case 'AZURE':
            return React.createElement(Badge, { color: "grey" }, "Azure");
        case 'GITHUB':
            return React.createElement(Badge, { color: "grey" }, "Github");
        case 'MICROSOFT-GRAPH':
            return React.createElement(Badge, { color: "grey" }, "Microsoft-Graph");
        case 'OPEN-AI':
            return React.createElement(Badge, { color: "grey" }, "Open AI");
        default:
            return React.createElement(Badge, { color: "grey" }, provider);
    }
};
export const getLogoAsUrl = (provider) => {
    switch (provider) {
        case 'AWS':
            return AWSSVGIconUrl;
        case 'GCP':
            return GCPSVGIconUrl;
        case 'AZURE':
            return AzureSVGIconUrl;
        case 'GITHUB':
            return GithubSVGIconUrl;
        case 'MICROSOFT-GRAPH':
            return MicrosoftGraphSVGIconUrl;
        case 'OPEN-AI':
            return OpenAIIconSVGUrl;
        case 'ELASTIC-CLOUD':
            return ElasticCloudIconSVGUrl;
        default:
            return DefaultProviderSVGUrl;
    }
};
export function getProviderDescription(provider) {
    switch (provider === null || provider === void 0 ? void 0 : provider.toUpperCase()) {
        case 'AWS':
            return 'Amazon Web Services (AWS) is a cloud computing platform that provides a suite of on-demand, scalable, and customizable services for businesses and individuals.';
        case 'GCP':
            return 'Google Cloud Platform (GCP) is a cloud computing platform and infrastructure created by Google for building, deploying, and managing applications and services on the web.';
        case 'AZURE':
            return 'Microsoft Azure is a cloud computing platform and infrastructure created by Microsoft for building, deploying, and managing applications and services through a global network of Microsoft-managed data centers.';
        case 'GITHUB':
            return 'GitHub is a web-based platform for software development and version control using Git. It provides a variety of features for collaborative coding, including issue tracking, pull requests, and code review.';
        case 'MICROSOFT-GRAPH':
            return 'Microsoft Graph API is a RESTful web API that enables developers to access Microsoft 365 data, including users, groups, mail, calendar, contacts, files, and more, to build integrated and intelligent solutions.';
        case 'OPEN-AI':
            return 'OpenAI is an artificial intelligence research lab comprised of both for-profit and non-profit arms, focusing on advancing digital intelligence in a way that is beneficial to humanity.';
        case 'ELASTIC-CLOUD':
            return 'Elastic Cloud offers a managed Elastic Stack (Elasticsearch, Kibana, Beats, and Logstash) experience. It simplifies deployment, monitoring, and scaling in the cloud.';
        default:
            return '';
    }
}
const getProviderOptionObject = (provider) => {
    return {
        label: provider === null || provider === void 0 ? void 0 : provider.name,
        value: String(provider === null || provider === void 0 ? void 0 : provider.id),
        iconUrl: getLogoAsUrl(provider === null || provider === void 0 ? void 0 : provider.name),
        description: getProviderDescription(provider === null || provider === void 0 ? void 0 : provider.name),
    };
};
export const makeProvidersSelectionOptions = (providers) => {
    var _a, _b;
    return ((_a = providers === null || providers === void 0 ? void 0 : providers.Providers) === null || _a === void 0 ? void 0 : _a.length) > 0
        ? (_b = providers === null || providers === void 0 ? void 0 : providers.Providers) === null || _b === void 0 ? void 0 : _b.map((provider) => {
            return getProviderOptionObject(provider);
        })
        : [];
};
export const makeDomainACLsActionSelectionOptions = () => {
    return [
        {
            label: capitalizeFirst(ACLAction.BLOCK.toLowerCase()),
            value: capitalizeFirst(ACLAction.BLOCK.toLowerCase()),
        },
        {
            label: capitalizeFirst(ACLAction.ALLOW.toLowerCase()),
            value: capitalizeFirst(ACLAction.ALLOW.toLowerCase())
        }
    ];
};
export const makeRiskRatingText = (rating) => {
    return !rating ? '' : toPascalCase(rating.toLowerCase().replaceAll('_', ' '));
};
export const makeRiskRatingSelectionOptions = () => {
    const options = [];
    for (const rating in RISK_RATING) {
        options.push({
            label: makeRiskRatingText(rating),
            value: rating
        });
    }
    return options;
};
export const sortMultiColumns = (data, columns) => {
    const columnsLength = columns === null || columns === void 0 ? void 0 : columns.length;
    if (columnsLength > 0) {
        return data.sort((a, b) => {
            for (let i = 0; i < columnsLength; i++) {
                const currColumn = columns[i];
                if (a[currColumn].toLowerCase() < b[currColumn].toLowerCase()) {
                    return -1;
                }
                if (a[currColumn].toLowerCase() > b[currColumn].toLowerCase()) {
                    return 1;
                }
            }
            return 0;
        });
    }
    return data;
};
export const constructTagString = (tagsData) => {
    let tagString = "";
    if ((tagsData === null || tagsData === void 0 ? void 0 : tagsData.length) > 0) {
        const tagsLength = tagsData.length - 1;
        tagsData.forEach((item, index) => {
            const itemString = `${item.key}:${item.value}`;
            tagString = (index === tagsLength) ? (tagString + itemString) : (`${tagString + itemString},`);
        });
    }
    return tagString;
};
export const TAGS_COLUMN_DEFINITIONS = [
    {
        id: 'key',
        sortingField: 'key',
        header: React.createElement("div", { className: "table-header-padding" }, " Key "),
        cell: item => React.createElement("div", { className: "table-header-padding" },
            " ",
            item.key || '-',
            " "),
        width: 300
    },
    {
        id: 'value',
        sortingField: 'value',
        header: 'Value',
        cell: item => item.value || '-'
    }
];
export const COMPLIANCE_MAPPINGS_COLUMN_DEFINITIONS = [
    {
        id: 'key',
        sortingField: 'key',
        header: React.createElement("div", { className: "table-header-padding" }, " Framework "),
        cell: item => React.createElement("div", { className: "table-header-padding" },
            " ",
            item.key || '-',
            " "),
        width: 300
    },
    {
        id: 'value',
        sortingField: 'value',
        header: 'Control Identifier',
        cell: item => item.value || '-'
    }
];
export const EmptyTag = {
    key: '',
    value: '',
    existing: false
};
export var AttributeErrorCode;
(function (AttributeErrorCode) {
    AttributeErrorCode["INVALID_KEY"] = "INVALID_KEY";
    AttributeErrorCode["INVALID_VALUE"] = "INVALID_VALUE";
    AttributeErrorCode["INVALID_VALUE_ADDITIONAL"] = "INVALID_VALUE_ADDITIONAL";
    AttributeErrorCode["INVALID_KEY_AND_VALUE"] = "INVALID_KEY_AND_VALUE";
    AttributeErrorCode["DUPLICATE_KEY"] = "DUPLICATE_KEY";
    AttributeErrorCode["DUPLICATE_VALUE"] = "DUPLICATE_VALUE";
})(AttributeErrorCode || (AttributeErrorCode = {}));
export const getEmptyAttribute = () => {
    return {
        id: getRandomUUID(),
        key: '',
        value: '',
    };
};
export var RISK_RATING;
(function (RISK_RATING) {
    RISK_RATING["CRITICAL"] = "CRITICAL";
    RISK_RATING["HIGH"] = "HIGH";
    RISK_RATING["MEDIUM"] = "MEDIUM";
    RISK_RATING["LOW"] = "LOW";
    RISK_RATING["VERY_LOW"] = "VERY_LOW";
})(RISK_RATING || (RISK_RATING = {}));
export var RISK_RATING_PASCAL_CASE;
(function (RISK_RATING_PASCAL_CASE) {
    RISK_RATING_PASCAL_CASE["CRITICAL"] = "Critical";
    RISK_RATING_PASCAL_CASE["HIGH"] = "High";
    RISK_RATING_PASCAL_CASE["MEDIUM"] = "Medium";
    RISK_RATING_PASCAL_CASE["LOW"] = "Low";
    RISK_RATING_PASCAL_CASE["VERY_LOW"] = "Very Low";
})(RISK_RATING_PASCAL_CASE || (RISK_RATING_PASCAL_CASE = {}));
export var RISK_RATING_NUMERICAL;
(function (RISK_RATING_NUMERICAL) {
    RISK_RATING_NUMERICAL[RISK_RATING_NUMERICAL["CRITICAL"] = 5] = "CRITICAL";
    RISK_RATING_NUMERICAL[RISK_RATING_NUMERICAL["HIGH"] = 4] = "HIGH";
    RISK_RATING_NUMERICAL[RISK_RATING_NUMERICAL["MEDIUM"] = 3] = "MEDIUM";
    RISK_RATING_NUMERICAL[RISK_RATING_NUMERICAL["LOW"] = 2] = "LOW";
    RISK_RATING_NUMERICAL[RISK_RATING_NUMERICAL["VERY_LOW"] = 1] = "VERY_LOW";
})(RISK_RATING_NUMERICAL || (RISK_RATING_NUMERICAL = {}));
export const getRiskRatingNumerical = (rating) => {
    var _a;
    return (_a = RISK_RATING_NUMERICAL === null || RISK_RATING_NUMERICAL === void 0 ? void 0 : RISK_RATING_NUMERICAL[rating]) !== null && _a !== void 0 ? _a : 0;
};
export const formatNumberToKMB = (num) => {
    return Intl.NumberFormat('en-US', { maximumFractionDigits: 1, notation: 'compact', compactDisplay: 'short' }).format(num);
};
const getDateTimeOptionsForRelativeCustomKeys = (unit, amount) => {
    switch (unit) {
        case 'second':
            return `now-${amount}s`;
        case 'minute':
            return `now-${amount}m`;
        case 'hour':
            return `now-${amount}h`;
        case 'day':
            return `now-${amount}d`;
        case 'week':
            return `now-${amount}w`;
        case 'month':
            return `now-${amount}M`;
        case 'year':
            return `now-${amount}y`;
        default:
            return 'now';
    }
};
const getDateTimeOptionsForPreExistingKeys = (key) => {
    switch (key) {
        case 'last-10-minutes':
            return 'now-10m';
        case 'last-60-minutes':
            return 'now-60m';
        case 'last-4-hours':
            return 'now-4h';
        case 'last-24-hours':
            return 'now-24h';
        default:
            return 'now';
    }
};
export const getDateTimeRangeOptions = (detail) => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j;
    let startTime = 'now-4h';
    let endTime = 'now';
    if (((_a = detail === null || detail === void 0 ? void 0 : detail.value) === null || _a === void 0 ? void 0 : _a.type) === 'absolute') {
        startTime = new Date((_b = detail === null || detail === void 0 ? void 0 : detail.value) === null || _b === void 0 ? void 0 : _b.startDate).toISOString();
        endTime = new Date((_c = detail === null || detail === void 0 ? void 0 : detail.value) === null || _c === void 0 ? void 0 : _c.endDate).toISOString();
    }
    // predetermined key exists, lets fetch the value
    else if (((_d = detail === null || detail === void 0 ? void 0 : detail.value) === null || _d === void 0 ? void 0 : _d.type) === 'relative' && ((_e = detail === null || detail === void 0 ? void 0 : detail.value) === null || _e === void 0 ? void 0 : _e.key)) {
        startTime = getDateTimeOptionsForPreExistingKeys((_f = detail === null || detail === void 0 ? void 0 : detail.value) === null || _f === void 0 ? void 0 : _f.key);
        endTime = 'now';
    }
    // no key exist lets make some
    else if (((_g = detail === null || detail === void 0 ? void 0 : detail.value) === null || _g === void 0 ? void 0 : _g.type) === 'relative') {
        startTime = getDateTimeOptionsForRelativeCustomKeys((_h = detail === null || detail === void 0 ? void 0 : detail.value) === null || _h === void 0 ? void 0 : _h.unit, (_j = detail === null || detail === void 0 ? void 0 : detail.value) === null || _j === void 0 ? void 0 : _j.amount);
        endTime = 'now';
    }
    return {
        startDateTime: startTime,
        endDateTime: endTime
    };
};
export const validateDateRange = (range) => {
    if (range.type === 'absolute') {
        const [startDateWithoutTime] = range.startDate.split('T');
        const [endDateWithoutTime] = range.endDate.split('T');
        if (!startDateWithoutTime || !endDateWithoutTime) {
            return {
                valid: false,
                errorMessage: 'The selected date range is incomplete. Select a start and end date for the date range.'
            };
        }
        const startEndDiff = new Date(range.startDate).getTime() - new Date(range.endDate).getTime();
        if (startEndDiff > 0) {
            return {
                valid: false,
                errorMessage: 'The selected date range is invalid. The start date must be before the end date.'
            };
        }
    }
    return { valid: true };
};
export const makeNotification = (type, message, dismissNotification) => {
    const id = getRandomUUID();
    return {
        id: id,
        type,
        content: message,
        statusIconAriaLabel: type,
        dismissible: true,
        onDismiss: () => dismissNotification(id)
    };
};
export const makePropertyFilterI18nStrings = (replaceProperties) => {
    const i18nStrings = {
        filteringAriaLabel: 'your choice',
        dismissAriaLabel: 'Dismiss',
        filteringPlaceholder: 'Filter Entities',
        groupValuesText: 'Values',
        groupPropertiesText: 'Properties',
        operatorsText: 'Operators',
        operationAndText: 'and',
        operationOrText: 'or',
        operatorLessText: 'Less than',
        operatorLessOrEqualText: 'Less than or equal',
        operatorGreaterText: 'Greater than',
        operatorGreaterOrEqualText: 'Greater than or equal',
        operatorContainsText: 'Contains',
        operatorDoesNotContainText: 'Does not contain',
        operatorEqualsText: 'Equals',
        operatorDoesNotEqualText: 'Does not equal',
        editTokenHeader: 'Edit filter',
        propertyText: 'Property',
        operatorText: 'Operator',
        valueText: 'Value',
        cancelActionText: 'Cancel',
        applyActionText: 'Apply',
        allPropertiesLabel: 'All properties',
        tokenLimitShowMore: 'Show more',
        tokenLimitShowFewer: 'Show fewer',
        clearFiltersText: 'Clear Filters',
        removeTokenButtonAriaLabel: token => `Remove token ${token.propertyKey} ${token.operator} ${token.value}`,
        enteredTextLabel: text => `Use: "${text}"`
    };
    Object.keys(i18nStrings).forEach(key => {
        if (replaceProperties === null || replaceProperties === void 0 ? void 0 : replaceProperties[key]) {
            i18nStrings[key] = replaceProperties === null || replaceProperties === void 0 ? void 0 : replaceProperties[key];
        }
    });
    return i18nStrings;
};
export const autoResizeOption = {
    customPreference: (customValue, setCustomValue) => {
        const { autoResize } = customValue;
        return (React.createElement(Toggle, { onChange: ({ detail }) => {
                setCustomValue(Object.assign(Object.assign({}, customValue), { autoResize: detail.checked }));
            }, checked: autoResize }, "Auto Resize "));
    }
};
export const makeDateRangeI18nStrings = () => {
    return {
        todayAriaLabel: 'Today',
        nextMonthAriaLabel: 'Next month',
        previousMonthAriaLabel: 'Previous month',
        customRelativeRangeDurationLabel: 'Duration',
        customRelativeRangeDurationPlaceholder: 'Enter duration',
        customRelativeRangeOptionLabel: 'Custom time range',
        customRelativeRangeOptionDescription: 'Set a custom historical time range',
        customRelativeRangeUnitLabel: 'Unit of time',
        formatRelativeRange: e => {
            const n = e.amount === 1 ? e.unit : `${e.unit}s`;
            return `Last ${e.amount} ${n}`;
        },
        formatUnit: (e, n) => (n === 1 ? e : `${e}s`),
        dateTimeConstraintText: '',
        relativeModeTitle: 'Relative Range',
        absoluteModeTitle: 'Absolute Range',
        relativeRangeSelectionHeading: 'Choose a time range',
        startDateLabel: 'Start date',
        endDateLabel: 'End date',
        startTimeLabel: 'Start time',
        endTimeLabel: 'End time',
        clearButtonLabel: 'Clear and Dismiss',
        cancelButtonLabel: 'Cancel',
        applyButtonLabel: 'Apply'
    };
};
export const makeDateRangeRelativeOptions = () => {
    return [
        {
            key: 'last-10-minutes',
            amount: 10,
            unit: 'minute',
            type: 'relative'
        },
        {
            key: 'last-60-minutes',
            amount: 60,
            unit: 'minute',
            type: 'relative'
        },
        {
            key: 'last-4-hours',
            amount: 4,
            unit: 'hour',
            type: 'relative'
        },
        {
            key: 'last-24-hours',
            amount: 24,
            unit: 'hour',
            type: 'relative'
        }
    ];
};
export const makeCodeEditorI18nStrings = () => {
    return {
        loadingState: 'Loading code editor',
        errorState: 'There was an error loading the code editor.',
        errorStateRecovery: 'Retry',
        editorGroupAriaLabel: 'Code editor',
        statusBarGroupAriaLabel: 'Status bar',
        cursorPosition: (row, column) => `Ln ${row}, Col ${column}`,
        errorsTab: 'Errors',
        warningsTab: 'Warnings',
        preferencesButtonAriaLabel: 'Preferences',
        paneCloseButtonAriaLabel: 'Close',
        preferencesModalHeader: 'Preferences',
        preferencesModalCancel: 'Cancel',
        preferencesModalConfirm: 'Confirm',
        preferencesModalWrapLines: 'Wrap Lines',
        preferencesModalTheme: 'Theme',
        preferencesModalLightThemes: 'Light themes',
        preferencesModalDarkThemes: 'Dark themes'
    };
};
export const makeSplitPanelI18nStrings = () => {
    return {
        preferencesTitle: 'Split panel preferences',
        preferencesPositionLabel: 'Split panel position',
        preferencesPositionDescription: 'Choose the default split panel position for the service.',
        preferencesPositionSide: 'Side',
        preferencesPositionBottom: 'Bottom',
        preferencesConfirm: 'Confirm',
        preferencesCancel: 'Cancel',
        closeButtonAriaLabel: 'Close panel',
        openButtonAriaLabel: 'Open panel',
        resizeHandleAriaLabel: 'Resize split panel'
    };
};
export const makeUnavailablePanelContent = (panelStrings) => {
    return Object.assign({ header: 'Record Not Found', body: React.createElement(ErrorPageWrapper, { handleLogout: () => { }, reloadPage: true, isHeightRequired: true }) }, (panelStrings ? { panelStrings: panelStrings } : {}));
};
export const makeEmptyPanelContent = (body, panelStrings) => {
    return Object.assign({ header: '0 record selected', body: body }, (panelStrings ? { panelStrings: panelStrings } : {}));
};
export const getInspectionSubmitButton = (submitType) => {
    switch (submitType) {
        case ServiceInspections.ENABLED:
            return 'Enable';
        case ServiceInspections.DISABLED:
            return 'Disable';
        case ServiceInspections.BYPASS:
            return 'Bypass';
    }
    return 'Submit';
};
export const getInspectionColor = (submitType) => {
    switch (submitType) {
        case ServiceInspections.ENABLED:
            return 'green';
        case ServiceInspections.DISABLED:
            return 'red';
        case ServiceInspections.BYPASS:
            return 'grey';
    }
    return 'grey';
};
export const formatExistingTags = (tags, isView = false) => {
    const formattedTags = tags === null || tags === void 0 ? void 0 : tags.map((tag) => {
        return {
            key: tag.key,
            value: tag.value,
            existing: true
        };
    });
    if (isView) {
        return formattedTags;
    }
    return (formattedTags === null || formattedTags === void 0 ? void 0 : formattedTags.length) > 0 ? formattedTags : [EmptyTag];
};
export const formatExistingComplianceMappings = (mappings, isView = false) => {
    const formattedMappings = mappings === null || mappings === void 0 ? void 0 : mappings.map((mapping) => {
        return {
            id: mapping.id,
            key: mapping.key,
            value: mapping.value,
        };
    });
    if (isView) {
        return formattedMappings;
    }
    return (formattedMappings === null || formattedMappings === void 0 ? void 0 : formattedMappings.length) > 0 ? formattedMappings : [getEmptyAttribute()];
};
export const paginateMultiselectOptions = (items, page = 1, perPage = 5) => {
    const offset = perPage * (page - 1);
    const totalPages = Math.ceil(items.length / perPage);
    const paginatedItems = items.slice(offset, perPage * page);
    return {
        previousPage: page - 1 ? page - 1 : null,
        nextPage: (totalPages > page) ? page + 1 : null,
        total: items.length,
        totalPages: totalPages,
        items: paginatedItems
    };
};
export const useQueryParams = () => {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
};
export const getUrlParams = (query, name) => {
    return query.get(name);
};
export const setUrlParams = (history, query, name, value) => {
    query.set(name, value);
    history.replace(`${location.pathname}?${query}`);
};
export const deleteUrlParams = (history, query, name) => {
    query.delete(name);
    history.replace(`${location.pathname}?${query}`);
};
export const getReadableOperatorValues = (operator) => {
    switch (operator) {
        case "=":
            return PROPERTY_FILTER_OPERATOR_VALUES.EQUAL_TO;
        case "!=":
            return PROPERTY_FILTER_OPERATOR_VALUES.NOT_EQUAL_TO;
        case ":":
            return PROPERTY_FILTER_OPERATOR_VALUES.CONTAINS;
        case "!:":
            return PROPERTY_FILTER_OPERATOR_VALUES.DOES_NOT_CONTAIN;
        case "<":
            return PROPERTY_FILTER_OPERATOR_VALUES.LESS_THAN;
        case "<=":
            return PROPERTY_FILTER_OPERATOR_VALUES.LESS_THAN_OR_EQUAL_TO;
        case ">":
            return PROPERTY_FILTER_OPERATOR_VALUES.GREATER_THAN;
        case ">=":
            return PROPERTY_FILTER_OPERATOR_VALUES.GREATER_THAN_OR_EQUAL_TO;
    }
};
export const getPropertyFilteringOperatorValues = (operator) => {
    switch (operator) {
        case PROPERTY_FILTER_OPERATOR_VALUES.EQUAL_TO:
            return "=";
        case PROPERTY_FILTER_OPERATOR_VALUES.NOT_EQUAL_TO:
            return "!=";
        case PROPERTY_FILTER_OPERATOR_VALUES.CONTAINS:
            return ":";
        case PROPERTY_FILTER_OPERATOR_VALUES.DOES_NOT_CONTAIN:
            return "!:";
        case PROPERTY_FILTER_OPERATOR_VALUES.LESS_THAN:
            return "<";
        case PROPERTY_FILTER_OPERATOR_VALUES.LESS_THAN_OR_EQUAL_TO:
            return "<=";
        case PROPERTY_FILTER_OPERATOR_VALUES.GREATER_THAN:
            return ">";
        case PROPERTY_FILTER_OPERATOR_VALUES.GREATER_THAN_OR_EQUAL_TO:
            return ">=";
    }
};
