import { PATH_NOTIFICATIONS, PATH_PROXY_DEPLOYMENTS, PATH_SETTINGS, PATHS } from "@/presentation/common-utils/constants";
import { capitalizeFirst } from "@/lib/misc/utils";
import { SETTINGS_TABS } from "@/presentation/pages/settings/settings-types";
const homeCrumb = {
    text: 'Home',
    href: '/',
};
export const makeBreadcrumbsFromURLPath = (urlPath) => {
    let crumbs = [];
    if (urlPath) {
        const paths = urlPath.split('/').filter(path => !!path);
        let previousPathPart = '';
        crumbs = paths.map(pathPart => {
            previousPathPart = previousPathPart + '/' + pathPart;
            return {
                text: capitalizeFirst(pathPart),
                href: previousPathPart,
            };
        });
    }
    return [homeCrumb, ...crumbs];
};
export const makeHierarchicalBreadcrumbsFromURLPath = (urlPath) => {
    const crumbs = [];
    if (urlPath) {
        const paths = urlPath.split('/').filter(path => !!path);
        let previousPathPart = '';
        let topEntityCrumbCreated = false;
        // We use for rather than a map to process two at a time
        for (let i = 0; i < paths.length; i += 2) {
            // making sure values exist in the specified index
            // and not throw Array out of bounds
            if (!topEntityCrumbCreated && (paths === null || paths === void 0 ? void 0 : paths[i]) && (paths === null || paths === void 0 ? void 0 : paths[i + 1])) {
                previousPathPart = '/' + paths[i];
                const topEntityCrumb = {
                    text: capitalizeFirst(paths[i]),
                    href: previousPathPart
                };
                crumbs.push(topEntityCrumb);
                previousPathPart = previousPathPart + '/' + paths[i + 1];
                const topEntityNumericCrumb = {
                    text: paths[i + 1],
                    href: previousPathPart
                };
                crumbs.push(topEntityNumericCrumb);
                topEntityCrumbCreated = true;
            }
            // rest of the crumbs with entity and numeric value
            else if ((paths === null || paths === void 0 ? void 0 : paths[i]) && (paths === null || paths === void 0 ? void 0 : paths[i + 1])) {
                previousPathPart = previousPathPart + '/' + paths[i] + '/' + paths[i + 1];
                const crumb = {
                    text: capitalizeFirst(paths[i]) + ':' + paths[i + 1],
                    href: previousPathPart
                };
                crumbs.push(crumb);
            }
            // add the last part as it is
            else {
                previousPathPart = previousPathPart + '/' + paths[i];
                const crumb = {
                    text: capitalizeFirst(paths[i]),
                    href: previousPathPart
                };
                crumbs.push(crumb);
            }
        }
    }
    return crumbs;
};
export const breadcrumbsReporting = [
    homeCrumb,
    {
        text: 'Reporting',
        href: PATHS.REPORTING,
    }
];
export const breadcrumbsProxies = [
    {
        text: 'Proxies',
        href: PATHS.PROXIES,
    }
];
export const breadcrumbsProxiesWithHome = [
    homeCrumb,
    ...breadcrumbsProxies
];
export const breadcrumbsProxiesDeploymentsCreate = (proxyId) => {
    return [
        ...breadcrumbsProxiesWithHome,
        {
            text: proxyId,
            href: `${PATHS.PROXIES}/${proxyId}`,
        },
        {
            text: 'Create Deployment',
            href: `${PATHS.PROXIES}/${proxyId}${PATH_PROXY_DEPLOYMENTS.CREATE}`,
        },
    ];
};
export const breadcrumbsSettings = [
    homeCrumb,
    {
        text: 'Settings',
        href: PATHS.SETTINGS,
    }
];
export const breadcrumbsSettingsEdit = [
    ...breadcrumbsSettings,
    {
        text: 'Edit',
        href: `${PATH_SETTINGS.EDIT}`,
    }
];
export const breadcrumbsSettingsOrgPolicyFunctions = [
    ...breadcrumbsSettings,
    {
        text: 'Organization Policy Functions',
        href: `${PATHS.SETTINGS}?tabId=${SETTINGS_TABS.ORG_POLICY_FUNCTIONS}`
    }
];
export const breadcrumbsSettingsNotificationsDestinations = [
    ...breadcrumbsSettings,
    {
        text: 'Notification Destinations',
        href: '#',
    }
];
export const breadcrumbsSettingsNotificationsDestinationsCreate = [
    ...breadcrumbsSettingsNotificationsDestinations,
    {
        text: 'Create',
        href: `${PATH_SETTINGS.NOTIFICATIONS_DESTINATIONS_CREATE}`,
    }
];
export const breadcrumbsSettingsOrgPolicyFunctionsEdit = [
    ...breadcrumbsSettingsOrgPolicyFunctions,
    {
        text: 'Edit',
        href: '',
    }
];
export const breadcrumbsSettingsCloudTenantStructure = [
    ...breadcrumbsSettings,
    {
        text: 'Cloud Tenant Structures',
        href: `${PATHS.SETTINGS}?tabId=${SETTINGS_TABS.CLOUD_TENANTS}`
    }
];
export const breadcrumbsSettingsCloudTenantStructureCreate = [
    ...breadcrumbsSettingsCloudTenantStructure,
    {
        text: 'Create',
        href: `${PATH_SETTINGS.CLOUD_TENANTS_CREATE}`,
    }
];
export const breadcrumbsSettingsCloudTenantStructureEdit = (tenantID) => {
    return [
        ...breadcrumbsSettingsCloudTenantStructure,
        {
            'text': tenantID,
            href: `${PATH_SETTINGS.CLOUD_TENANTS}/${tenantID}`,
        },
        {
            text: 'Edit',
            href: ``,
        }
    ];
};
export const breadcrumbsSettingsDomainACLsStructure = [
    ...breadcrumbsSettings,
    {
        text: 'Domain ACLs',
        href: `${PATHS.SETTINGS}?tabId=${SETTINGS_TABS.DOMAIN_ACLS}`
    }
];
export const breadcrumbsSettingsDomainACLsStructureCreate = [
    ...breadcrumbsSettingsDomainACLsStructure,
    {
        text: 'Create',
        href: `${PATH_SETTINGS.DOMAIN_ACLS_CREATE}`,
    }
];
export const breadcrumbsSettingsDomainACLsStructureEdit = (tenantID) => {
    return [
        ...breadcrumbsSettingsDomainACLsStructure,
        {
            'text': tenantID,
            href: `${PATH_SETTINGS.DOMAIN_ACLS_LIST}`,
        },
        {
            text: 'Edit',
            href: ``,
        }
    ];
};
export const breadcrumbsMyProfile = [
    homeCrumb,
    {
        text: 'My Profile',
        href: PATHS.MYPROFILE,
    }
];
export const breadcrumbsMonitoring = [
    homeCrumb,
    {
        text: 'Monitoring',
        href: PATHS.MONITORING,
    }
];
export const breadcrumbsNotificationsMonitors = [
    homeCrumb,
    {
        text: 'Notifications',
        href: PATHS.NOTIFICATIONS,
    }
];
export const breadcrumbsNotificationsMonitorsCreate = [
    ...breadcrumbsNotificationsMonitors,
    {
        text: 'Create Notification',
        href: `${PATH_NOTIFICATIONS.CREATE}`
    }
];
