var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { PagesBaseHandler, ServiceInspections } from '@/presentation/handlers';
import { booleanToString, getPropertyFilteringOptions } from '@/presentation/common-utils/common-utils';
import { getRandomUUID, isJSON } from '@/lib/misc/utils';
import { getOrgIDFromToken } from '@/lib/auth0/utils';
import { constructTagString, makeRiskRatingText, RISK_RATING_PASCAL_CASE } from '@/presentation/pages';
export class RulesHandler extends PagesBaseHandler {
    constructor(remoteHandlers, remotes) {
        super();
        this.setRulesTagsAndPolicies = (ruleTagsAndPolicies) => {
            this.ruleTagsAndPolicies = ruleTagsAndPolicies;
            return this;
        };
        this.extractActionsFromConfig = (config) => {
            let actions = [];
            if (config && isJSON(config)) {
                const parsed = JSON.parse(config);
                if (parsed === null || parsed === void 0 ? void 0 : parsed.actions) {
                    actions = parsed.actions;
                }
            }
            return actions;
        };
        this.isChildRule = (ruleDependencies) => {
            let isChild = false;
            // if the rule has a parent whose dependencies_enabled is true, then this is a child
            if (ruleDependencies.length > 0 && ruleDependencies.filter(dep => { var _a; return ((_a = dep === null || dep === void 0 ? void 0 : dep.ruleByRuleId) === null || _a === void 0 ? void 0 : _a.dependencies_enabled) === true; }).length > 0) {
                isChild = true;
            }
            return isChild;
        };
        this.isParentRule = (rule) => {
            var _a;
            let isParent = false;
            // if the rule has dependencies_enabled is true, and atleast one child, then this is a parent
            if ((rule === null || rule === void 0 ? void 0 : rule.dependencies_enabled) && ((_a = rule === null || rule === void 0 ? void 0 : rule.ruleDependenciesByRuleId) === null || _a === void 0 ? void 0 : _a.length) > 0) {
                isParent = true;
            }
            return isParent;
        };
        this.getFormattedOptions = (listRules) => {
            var _a;
            return (_a = listRules === null || listRules === void 0 ? void 0 : listRules.Rules) === null || _a === void 0 ? void 0 : _a.map((rule) => {
                return { value: rule.id.toString(), label: rule.description };
            });
        };
        this.getFormattedConfig = (rulesInfo) => {
            const configData = [...rulesInfo.details.config, ...rulesInfo.details.action];
            const configDataObject = configData.reduce((obj, record) => {
                const { propertyKey, value } = record;
                obj[propertyKey] = [...obj[propertyKey] || [], value];
                return obj;
            }, []);
            const config = {
                endpoints: [...new Set(configDataObject.endpoint)],
                methods: [...new Set(configDataObject.method)],
                paths: [...new Set(configDataObject.path)],
                actions: [...new Set(configDataObject.action)]
            };
            return config;
        };
        this.getCreateParams = (rulesInfo) => {
            var _a, _b, _c, _d, _e, _f, _g, _h;
            const config = this.getFormattedConfig(rulesInfo);
            const rulesInsertInput = {
                description: rulesInfo.details.description,
                service_id: parseInt(rulesInfo.details.service.value, 10),
                tags: (_b = (_a = rulesInfo === null || rulesInfo === void 0 ? void 0 : rulesInfo.tags) === null || _a === void 0 ? void 0 : _a.editorTags) === null || _b === void 0 ? void 0 : _b.filter(tag => !(tag === null || tag === void 0 ? void 0 : tag.markedForRemoval) && !!tag.key).map(tag => {
                    return {
                        key: tag === null || tag === void 0 ? void 0 : tag.key,
                        value: tag === null || tag === void 0 ? void 0 : tag.value
                    };
                }),
                enable_cfn_scan: Boolean(rulesInfo.details.cfnScan),
                log_request_body: Boolean(rulesInfo.details.logBody),
                enforce: Boolean(rulesInfo.details.enforceRule),
                policy: rulesInfo.policyConfig.policy,
                config: JSON.stringify(config),
                compliance_mappings: (_e = (_d = (_c = rulesInfo === null || rulesInfo === void 0 ? void 0 : rulesInfo.compliance) === null || _c === void 0 ? void 0 : _c.mappings) === null || _d === void 0 ? void 0 : _d.filter(mapping => !!mapping.key).map(mapping => {
                    return {
                        framework: mapping === null || mapping === void 0 ? void 0 : mapping.key,
                        control: mapping === null || mapping === void 0 ? void 0 : mapping.value
                    };
                })) !== null && _e !== void 0 ? _e : [],
                risk_rating: (_h = (_g = (_f = rulesInfo === null || rulesInfo === void 0 ? void 0 : rulesInfo.details) === null || _f === void 0 ? void 0 : _f.riskRating) === null || _g === void 0 ? void 0 : _g.value) !== null && _h !== void 0 ? _h : null,
            };
            return {
                objects: [rulesInsertInput]
            };
        };
        this.getImportParams = (rules) => {
            var _a;
            const insertRules = (_a = rules === null || rules === void 0 ? void 0 : rules.map(rule => {
                var _a, _b;
                return {
                    description: rule.description,
                    service_id: rule.serviceID,
                    tags: rule.tags,
                    log_request_body: true,
                    enforce: true,
                    policy: rule.policy,
                    config: rule.config,
                    imported_from: rule.id,
                    // @ts-ignore
                    risk_rating: (rule === null || rule === void 0 ? void 0 : rule.riskRating) !== '[null]' ? rule === null || rule === void 0 ? void 0 : rule.riskRating : null,
                    compliance_mappings: (_b = (_a = rule === null || rule === void 0 ? void 0 : rule.complianceMappings) === null || _a === void 0 ? void 0 : _a.filter(mapping => !!mapping.key).map(mapping => {
                        return {
                            framework: mapping === null || mapping === void 0 ? void 0 : mapping.key,
                            control: mapping === null || mapping === void 0 ? void 0 : mapping.value
                        };
                    })) !== null && _b !== void 0 ? _b : [],
                };
            })) !== null && _a !== void 0 ? _a : [];
            return {
                objects: insertRules
            };
        };
        this.getUpdateParams = (rulesInfo) => {
            var _a, _b, _c, _d, _e, _f, _g, _h;
            const config = this.getFormattedConfig(rulesInfo);
            return {
                id: rulesInfo.details.id,
                description: rulesInfo.details.description,
                tags: (_b = (_a = rulesInfo === null || rulesInfo === void 0 ? void 0 : rulesInfo.tags) === null || _a === void 0 ? void 0 : _a.editorTags) === null || _b === void 0 ? void 0 : _b.filter(tag => !(tag === null || tag === void 0 ? void 0 : tag.markedForRemoval) && !!tag.key).map(tag => {
                    return {
                        key: tag === null || tag === void 0 ? void 0 : tag.key,
                        value: tag === null || tag === void 0 ? void 0 : tag.value
                    };
                }),
                // control -- optional
                // framework -- mandatory
                compliance_mappings: (_e = (_d = (_c = rulesInfo === null || rulesInfo === void 0 ? void 0 : rulesInfo.compliance) === null || _c === void 0 ? void 0 : _c.mappings) === null || _d === void 0 ? void 0 : _d.filter(mapping => !!mapping.key).map(mapping => {
                    var _a, _b;
                    return {
                        framework: (_a = mapping === null || mapping === void 0 ? void 0 : mapping.key) === null || _a === void 0 ? void 0 : _a.trim(),
                        control: (_b = mapping === null || mapping === void 0 ? void 0 : mapping.value) === null || _b === void 0 ? void 0 : _b.trim()
                    };
                })) !== null && _e !== void 0 ? _e : [],
                risk_rating: (_h = (_g = (_f = rulesInfo === null || rulesInfo === void 0 ? void 0 : rulesInfo.details) === null || _f === void 0 ? void 0 : _f.riskRating) === null || _g === void 0 ? void 0 : _g.value) !== null && _h !== void 0 ? _h : null,
                enable_cfn_scan: Boolean(rulesInfo.details.cfnScan),
                log_request_body: Boolean(rulesInfo.details.logBody),
                enforce: Boolean(rulesInfo.details.enforceRule),
                config: JSON.stringify(config),
                policy: rulesInfo.policyConfig.policy,
                dependencies_enabled: false
            };
        };
        this.remotes = remotes;
        this.remoteHandlers = remoteHandlers;
    }
    deleteBulk(ids) {
        var _a;
        return __awaiter(this, void 0, void 0, function* () {
            const result = yield this.remotes.rules.deleteBulk({
                ids: ids
            });
            return {
                success: ((_a = result === null || result === void 0 ? void 0 : result.delete_Rules) === null || _a === void 0 ? void 0 : _a.affected_rows) > 0,
                response: result
            };
        });
    }
    getEntity(id) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.remotes.rules.get({
                rule_id: id
            });
        });
    }
    deleteEntity(id) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.remotes.rules.delete({
                rule_id: id
            });
        });
    }
    extractFilters(listOf) {
        const uniqueServices = [];
        listOf.Rules.forEach((item) => {
            if (uniqueServices.includes(item.Service.GlobalService.name)) {
                return;
            }
            uniqueServices.push(item.Service.GlobalService.name);
        });
        uniqueServices.forEach((svc, idx) => {
            uniqueServices[idx] = { value: svc };
        });
        return uniqueServices;
    }
    getFormattedEntity(rulesAndPoliciesV4) {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l;
        const rule = (_a = rulesAndPoliciesV4 === null || rulesAndPoliciesV4 === void 0 ? void 0 : rulesAndPoliciesV4.Rules) === null || _a === void 0 ? void 0 : _a[0];
        let actions = [];
        if ((rule === null || rule === void 0 ? void 0 : rule.config) && isJSON(rule.config)) {
            const config = JSON.parse(rule.config);
            if (config === null || config === void 0 ? void 0 : config.actions) {
                actions = config.actions;
            }
        }
        const service = {
            label: rule.Service.GlobalService.name,
            value: String(rule.Service.id)
        };
        const provider = {
            label: rule.Service.GlobalService.Provider.name,
            value: String(rule.Service.GlobalService.Provider.id)
        };
        let riskRating = undefined;
        if (rule === null || rule === void 0 ? void 0 : rule.risk_rating) {
            riskRating = {
                label: makeRiskRatingText(rule === null || rule === void 0 ? void 0 : rule.risk_rating),
                value: String(rule === null || rule === void 0 ? void 0 : rule.risk_rating)
            };
        }
        return {
            id: rule.id,
            description: rule.description,
            service: service,
            provider: provider,
            actions: actions,
            policy: ((rule === null || rule === void 0 ? void 0 : rule.policy) !== '') ? rule.policy : null,
            logBody: (_b = rule.log_request_body) !== null && _b !== void 0 ? _b : false,
            enforceRule: (_c = rule.enforce) !== null && _c !== void 0 ? _c : false,
            cfnScan: (_d = rule.enable_cfn_scan) !== null && _d !== void 0 ? _d : false,
            isResourceDependencyAttached: false,
            isDependentRule: rule.dependencies_enabled,
            tags: rule.tags,
            initialConfig: (rule === null || rule === void 0 ? void 0 : rule.config) || '',
            version: (_e = rule === null || rule === void 0 ? void 0 : rule.version) !== null && _e !== void 0 ? _e : 0,
            importedFrom: (_g = (_f = rule === null || rule === void 0 ? void 0 : rule.ImportedFrom) === null || _f === void 0 ? void 0 : _f.id) !== null && _g !== void 0 ? _g : 0,
            importedFromVersion: (_j = (_h = rule === null || rule === void 0 ? void 0 : rule.ImportedFrom) === null || _h === void 0 ? void 0 : _h.version) !== null && _j !== void 0 ? _j : 0,
            riskRating: riskRating,
            complianceMappings: (_l = (_k = rule === null || rule === void 0 ? void 0 : rule.compliance_mappings) === null || _k === void 0 ? void 0 : _k.map(mapping => {
                return {
                    id: getRandomUUID(),
                    key: mapping === null || mapping === void 0 ? void 0 : mapping.framework,
                    value: mapping === null || mapping === void 0 ? void 0 : mapping.control
                };
            })) !== null && _l !== void 0 ? _l : []
        };
    }
    getFormattedEntities(listRules) {
        // Extract rule dependencies and build up lists for filtering options
        const dependentRules = new Set();
        const servicesFilterMapList = [];
        const providersFilterMapList = [];
        const objects = listRules.Rules.map((rule) => {
            var _a, _b, _c, _d, _e, _f, _g;
            // Destructure relevant rule properties
            const { id, description, version, log_request_body, enforce, enable_cfn_scan, compliance_mappings, ProfileRules_aggregate, ruleDependenciesByRuleId, Service, risk_rating, CreatedByUser, UpdatedByUser, tags, ImportedFrom, created_at, updated_at } = rule;
            // Accumulate dependent rule IDs
            ruleDependenciesByRuleId.forEach((item) => dependentRules.add(item.dependent_rule_id));
            // Extract actions, compliance frameworks, and other rule details
            const actions = this.extractActionsFromConfig(rule === null || rule === void 0 ? void 0 : rule.config);
            const uniqueComplianceFrameworks = this.remoteHandlers.remoteComplianceMappingsHandler.getUniqueComplianceFrameworks(compliance_mappings);
            // Update filter map lists
            servicesFilterMapList.push(Service.GlobalService.name);
            providersFilterMapList.push(Service.GlobalService.Provider.name);
            // Helper function for formatted user name
            const formatUserName = (user) => user ? `${user.given_name || ''} ${user.family_name || ''}` : '';
            // Helper function to map compliance mappings
            const mapComplianceMappings = (_a = compliance_mappings === null || compliance_mappings === void 0 ? void 0 : compliance_mappings.map(mapping => ({
                id: getRandomUUID(),
                key: mapping === null || mapping === void 0 ? void 0 : mapping.framework,
                value: mapping === null || mapping === void 0 ? void 0 : mapping.control
            }))) !== null && _a !== void 0 ? _a : [];
            return {
                id,
                searchableID: String(id),
                description,
                service: Service.GlobalService.name,
                provider: Service.GlobalService.Provider.name,
                actions,
                actionsConcat: actions.join(', '),
                version,
                importedFrom: (_b = ImportedFrom === null || ImportedFrom === void 0 ? void 0 : ImportedFrom.id) !== null && _b !== void 0 ? _b : 0,
                importedFromVersion: (_c = ImportedFrom === null || ImportedFrom === void 0 ? void 0 : ImportedFrom.version) !== null && _c !== void 0 ? _c : 0,
                logBody: booleanToString(log_request_body !== null && log_request_body !== void 0 ? log_request_body : false),
                enforceRule: booleanToString(enforce !== null && enforce !== void 0 ? enforce : false),
                cfnScan: booleanToString(enable_cfn_scan !== null && enable_cfn_scan !== void 0 ? enable_cfn_scan : false),
                numberOfProfiles: ((_d = ProfileRules_aggregate === null || ProfileRules_aggregate === void 0 ? void 0 : ProfileRules_aggregate.aggregate) === null || _d === void 0 ? void 0 : _d.count) ? String(ProfileRules_aggregate.aggregate.count) : '0',
                isResourceDependencyAttached: ruleDependenciesByRuleId.length > 0,
                isDependentRule: false,
                riskRating: risk_rating !== null && risk_rating !== void 0 ? risk_rating : '[null]',
                riskRatingPascalCase: (_e = RISK_RATING_PASCAL_CASE === null || RISK_RATING_PASCAL_CASE === void 0 ? void 0 : RISK_RATING_PASCAL_CASE[risk_rating]) !== null && _e !== void 0 ? _e : '[null]',
                complianceMappings: mapComplianceMappings,
                complianceFrameworks: (compliance_mappings === null || compliance_mappings === void 0 ? void 0 : compliance_mappings.length) ? uniqueComplianceFrameworks === null || uniqueComplianceFrameworks === void 0 ? void 0 : uniqueComplianceFrameworks.join(', ') : '[null]',
                created_at: created_at !== null && created_at !== void 0 ? created_at : '',
                created_by: (_f = CreatedByUser === null || CreatedByUser === void 0 ? void 0 : CreatedByUser.id) !== null && _f !== void 0 ? _f : '',
                updated_at: updated_at !== null && updated_at !== void 0 ? updated_at : '',
                updated_by: (_g = UpdatedByUser === null || UpdatedByUser === void 0 ? void 0 : UpdatedByUser.id) !== null && _g !== void 0 ? _g : '',
                createdByFullName: formatUserName(CreatedByUser),
                updatedByFullName: formatUserName(UpdatedByUser),
                tagString: constructTagString(tags || [])
            };
        });
        // Determine which rules are dependent
        const rulesListObject = objects.map((item) => (Object.assign(Object.assign({}, item), { isDependentRule: dependentRules.has(item.id) })));
        return [{
                rulesList: rulesListObject,
                servicesFilteringOptions: getPropertyFilteringOptions(servicesFilterMapList, 'service'),
                providersFilteringOptions: getPropertyFilteringOptions(providersFilterMapList, 'provider')
            }];
    }
    getFormattedManagedRules(listManagedRules) {
        var _a, _b;
        return (_b = (_a = listManagedRules === null || listManagedRules === void 0 ? void 0 : listManagedRules.ManagedRules) === null || _a === void 0 ? void 0 : _a.map((rule) => {
            var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s;
            const actions = this.extractActionsFromConfig(rule === null || rule === void 0 ? void 0 : rule.config);
            const uniqueComplianceFrameworks = this.remoteHandlers.remoteComplianceMappingsHandler.getUniqueComplianceFrameworks(rule === null || rule === void 0 ? void 0 : rule.compliance_mappings);
            return {
                id: rule.id,
                provider: rule.Service.GlobalService.Provider.name,
                providerID: rule.Service.GlobalService.Provider.id,
                description: rule.description,
                service: rule.Service.GlobalService.name,
                serviceID: (_d = (_c = (_b = (_a = rule.Service.GlobalService) === null || _a === void 0 ? void 0 : _a.Services) === null || _b === void 0 ? void 0 : _b[0]) === null || _c === void 0 ? void 0 : _c.id) !== null && _d !== void 0 ? _d : 0,
                globalServiceID: rule.Service.GlobalService.id,
                inspection: (_h = (_g = (_f = (_e = rule.Service.GlobalService) === null || _e === void 0 ? void 0 : _e.Services) === null || _f === void 0 ? void 0 : _f[0]) === null || _g === void 0 ? void 0 : _g.inspection) !== null && _h !== void 0 ? _h : ServiceInspections.DISABLED,
                config: rule.config,
                policy: rule.policy,
                version: rule.version,
                actionsConcat: actions.join(', '),
                actions: actions,
                tags: rule.tags,
                riskRating: (_j = rule === null || rule === void 0 ? void 0 : rule.risk_rating) !== null && _j !== void 0 ? _j : '[null]',
                riskRatingPascalCase: (_k = RISK_RATING_PASCAL_CASE === null || RISK_RATING_PASCAL_CASE === void 0 ? void 0 : RISK_RATING_PASCAL_CASE[rule === null || rule === void 0 ? void 0 : rule.risk_rating]) !== null && _k !== void 0 ? _k : '[null]',
                complianceMappings: (_m = (_l = rule === null || rule === void 0 ? void 0 : rule.compliance_mappings) === null || _l === void 0 ? void 0 : _l.map(mapping => {
                    return {
                        id: getRandomUUID(),
                        key: mapping === null || mapping === void 0 ? void 0 : mapping.framework,
                        value: mapping === null || mapping === void 0 ? void 0 : mapping.control
                    };
                })) !== null && _m !== void 0 ? _m : [],
                complianceFrameworks: ((_o = rule === null || rule === void 0 ? void 0 : rule.compliance_mappings) === null || _o === void 0 ? void 0 : _o.length) > 0 ? uniqueComplianceFrameworks === null || uniqueComplianceFrameworks === void 0 ? void 0 : uniqueComplianceFrameworks.join(', ') : '[null]',
                tagString: constructTagString((rule === null || rule === void 0 ? void 0 : rule.tags) || []),
                enforce: booleanToString((_p = rule === null || rule === void 0 ? void 0 : rule.enforce) !== null && _p !== void 0 ? _p : false),
                logBody: booleanToString((_q = rule.log_request_body) !== null && _q !== void 0 ? _q : false),
                created_at: (_r = rule === null || rule === void 0 ? void 0 : rule.created_at) !== null && _r !== void 0 ? _r : '',
                updated_at: (_s = rule === null || rule === void 0 ? void 0 : rule.updated_at) !== null && _s !== void 0 ? _s : '',
            };
        })) !== null && _b !== void 0 ? _b : [];
    }
    getSelectableInMemoryFormattedEntities(listRules, selectedOptions) {
        const formatted = listRules.Rules.map((rule) => {
            var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r;
            let actions = [];
            if ((rule === null || rule === void 0 ? void 0 : rule.config) && isJSON(rule.config)) {
                const config = JSON.parse(rule.config);
                if (config === null || config === void 0 ? void 0 : config.actions) {
                    actions = config.actions;
                }
            }
            const uniqueComplianceFrameworks = this.remoteHandlers.remoteComplianceMappingsHandler.getUniqueComplianceFrameworks(rule === null || rule === void 0 ? void 0 : rule.compliance_mappings);
            return {
                id: rule.id,
                searchableID: String(rule.id),
                description: rule.description,
                service: rule.Service.GlobalService.name,
                provider: rule.Service.GlobalService.Provider.name,
                actions: actions,
                actionsConcat: actions.join(', '),
                isSelected: selectedOptions.includes(rule.id),
                isDisabled: false,
                isChildRule: this.isChildRule(rule.RuleDependencies),
                isParentRule: this.isParentRule(rule),
                importedFrom: (_b = (_a = rule === null || rule === void 0 ? void 0 : rule.ImportedFrom) === null || _a === void 0 ? void 0 : _a.id) !== null && _b !== void 0 ? _b : 0,
                importedFromVersion: (_d = (_c = rule === null || rule === void 0 ? void 0 : rule.ImportedFrom) === null || _c === void 0 ? void 0 : _c.version) !== null && _d !== void 0 ? _d : 0,
                complianceFrameworks: ((_e = rule === null || rule === void 0 ? void 0 : rule.compliance_mappings) === null || _e === void 0 ? void 0 : _e.length) > 0 ? uniqueComplianceFrameworks === null || uniqueComplianceFrameworks === void 0 ? void 0 : uniqueComplianceFrameworks.join(', ') : '[null]',
                riskRating: (_f = rule === null || rule === void 0 ? void 0 : rule.risk_rating) !== null && _f !== void 0 ? _f : '[null]',
                created_at: (_g = rule === null || rule === void 0 ? void 0 : rule.created_at) !== null && _g !== void 0 ? _g : '',
                created_by: (_j = (_h = rule === null || rule === void 0 ? void 0 : rule.CreatedByUser) === null || _h === void 0 ? void 0 : _h.id) !== null && _j !== void 0 ? _j : '',
                updated_at: (_k = rule === null || rule === void 0 ? void 0 : rule.updated_at) !== null && _k !== void 0 ? _k : '',
                updated_by: (_m = (_l = rule === null || rule === void 0 ? void 0 : rule.UpdatedByUser) === null || _l === void 0 ? void 0 : _l.id) !== null && _m !== void 0 ? _m : '',
                createdByFullName: (rule === null || rule === void 0 ? void 0 : rule.CreatedByUser) ? `${((_o = rule === null || rule === void 0 ? void 0 : rule.CreatedByUser) === null || _o === void 0 ? void 0 : _o.given_name) || ''} ${((_p = rule === null || rule === void 0 ? void 0 : rule.CreatedByUser) === null || _p === void 0 ? void 0 : _p.family_name) || ''}` : '',
                updatedByFullName: (rule === null || rule === void 0 ? void 0 : rule.UpdatedByUser) ? `${((_q = rule === null || rule === void 0 ? void 0 : rule.UpdatedByUser) === null || _q === void 0 ? void 0 : _q.given_name) || ''} ${((_r = rule === null || rule === void 0 ? void 0 : rule.UpdatedByUser) === null || _r === void 0 ? void 0 : _r.family_name) || ''}` : '',
            };
        });
        formatted.sort((optionA, optionB) => {
            if (optionA.isSelected && optionB.isSelected) {
                return 0;
            }
            else if (optionA.isSelected && !optionB.isSelected) {
                return -1;
            }
            else {
                return 1;
            }
        });
        return formatted;
    }
    listEntity() {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.remotes.rules.list();
        });
    }
    listManagedRules() {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.remotes.rules.listManagedRules();
        });
    }
    getRulesByImportedFromIDs(params) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.remotes.rules.getRulesByImportedFromIDs(params);
        });
    }
    enableServices(rules) {
        return __awaiter(this, void 0, void 0, function* () {
            const token = yield this.remotes.rules.getToken();
            const orgID = getOrgIDFromToken(token);
            const objects = rules.map((rule) => {
                return {
                    global_service_id: rule.globalServiceID,
                    organization_id: orgID,
                    inspection: ServiceInspections.ENABLED
                };
            });
            const vars = {
                objects: objects
            };
            return yield this.remotes.rules.enableServices(vars);
        });
    }
    importManagedRules(rules) {
        return __awaiter(this, void 0, void 0, function* () {
            const createdParams = this.getImportParams(rules);
            return this.remotes.rules.create(createdParams);
        });
    }
    ;
    handleCreate(rulesInfo) {
        return __awaiter(this, void 0, void 0, function* () {
            const createdParams = this.getCreateParams(rulesInfo);
            return this.remotes.rules.create(createdParams);
        });
    }
    handleUpdate(rulesInfo) {
        return __awaiter(this, void 0, void 0, function* () {
            const updateParams = this.getUpdateParams(rulesInfo);
            return yield this.remotes.rules.updateV4(updateParams);
        });
    }
    getEntityForInmemorySelectable(input) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield input;
        });
    }
    downloadActionsData(currentProvider) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.remotes.rules.downloadActionsData(currentProvider);
        });
    }
}
