import { addColumnSortLabels } from '@/presentation/components/commons/labels';
import React from 'react';
import { Badge, Select } from '@cloudscape-design/components-themed/components';
import { getRandomUUID } from '@/lib/misc/utils';
import { ROLETYPE, UserRolesReadableText } from '@/presentation/pages/settings/settings-types';
import gravatarUrl from 'gravatar-url';
import { makeSelectRoles } from "@/presentation/pages/settings/user-access/common";
import { createdAtColumnDefinition } from '@/presentation/pages/common-config';
export const UserAccessListFilteringProperties = [
    {
        key: 'fullname',
        operators: [':', '!:'],
        propertyLabel: 'Name',
        groupValuesLabel: 'Name values'
    },
    {
        key: 'email',
        operators: [':', '!:', '=', '!='],
        propertyLabel: 'Email',
        groupValuesLabel: 'Email values'
    },
    {
        key: 'verified',
        operators: [':', '!:', '=', '!='],
        propertyLabel: 'Status',
        groupValuesLabel: 'Status values'
    },
    {
        key: 'roleName',
        operators: ['=', '!='],
        propertyLabel: 'Role',
        groupValuesLabel: 'Role values'
    }
];
export const UserAccessListFilteringOptions = [
    {
        propertyKey: 'roleName',
        value: UserRolesReadableText === null || UserRolesReadableText === void 0 ? void 0 : UserRolesReadableText[ROLETYPE.USER]
    },
    {
        propertyKey: 'roleName',
        value: UserRolesReadableText === null || UserRolesReadableText === void 0 ? void 0 : UserRolesReadableText[ROLETYPE.ADMIN]
    },
    {
        propertyKey: 'roleName',
        value: UserRolesReadableText === null || UserRolesReadableText === void 0 ? void 0 : UserRolesReadableText[ROLETYPE.READONLY]
    },
    {
        propertyKey: 'verified',
        value: 'ACTIVE'
    },
    {
        propertyKey: 'verified',
        value: 'INVITED'
    }
];
export const UserAccessListColumnDefinitions = (roles) => {
    const totalItems = makeSelectRoles(roles);
    return addColumnSortLabels([
        {
            id: 'name',
            sortingField: 'fullname',
            header: 'Name',
            cell: (item) => {
                var _a, _b;
                return (React.createElement("div", { className: "flex items-center gap-2", key: getRandomUUID() },
                    React.createElement("img", { className: "rounded-full", src: gravatarUrl((_b = (_a = item === null || item === void 0 ? void 0 : item.email) === null || _a === void 0 ? void 0 : _a.toLowerCase()) !== null && _b !== void 0 ? _b : '', { size: 28, default: 'retro' }), key: getRandomUUID(), alt: '', title: (item === null || item === void 0 ? void 0 : item.fullname) || '' }),
                    React.createElement("span", { key: getRandomUUID() }, item === null || item === void 0 ? void 0 : item.fullname)));
            },
            minWidth: 250
        },
        {
            id: 'email',
            sortingField: 'email',
            header: 'Email',
            cell: (item) => {
                var _a;
                return ((_a = item === null || item === void 0 ? void 0 : item.email) === null || _a === void 0 ? void 0 : _a.toLowerCase()) || '-';
            },
            minWidth: 200
        },
        {
            id: 'verified',
            sortingField: 'verified',
            header: 'Status',
            cell: (item) => {
                return (item === null || item === void 0 ? void 0 : item.verified) ? (React.createElement(Badge, { color: (item === null || item === void 0 ? void 0 : item.verified) === 'ACTIVE' ? 'green' : 'grey' }, item === null || item === void 0 ? void 0 : item.verified)) : '-';
            }
        },
        createdAtColumnDefinition,
        {
            id: 'roleName',
            header: 'Role',
            cell: (item) => {
                return (item === null || item === void 0 ? void 0 : item.roleName) ? (React.createElement(Badge, { key: getRandomUUID(), color: 'grey' }, (item === null || item === void 0 ? void 0 : item.roleName) || '')) : '-';
            },
            editConfig: {
                ariaLabel: 'Roles',
                editIconAriaLabel: 'editable',
                errorIconAriaLabel: 'Roles Error',
                editingCell: (item, { currentValue, setValue }) => {
                    var _a, _b;
                    const value = (_a = currentValue === null || currentValue === void 0 ? void 0 : currentValue.value) !== null && _a !== void 0 ? _a : String(item.roles[0].roleID);
                    return (React.createElement(Select, { autoFocus: true, expandToViewport: true, selectedOption: (_b = totalItems.find(option => option.value === value)) !== null && _b !== void 0 ? _b : null, onChange: event => {
                            var _a;
                            setValue((_a = event.detail.selectedOption) !== null && _a !== void 0 ? _a : totalItems === null || totalItems === void 0 ? void 0 : totalItems[0]);
                        }, options: totalItems, placeholder: "Select Roles" }));
                },
            }
        }
    ]);
};
export const UserAccessListTablePreferences = {
    pageSize: 30,
    visibleContent: ['name', 'email', 'verified', 'created_at', 'roleName',],
    wrapLines: false,
    custom: {
        autoResize: true
    }
};
export const UserAccessListTableVisibleContentOptions = [
    {
        label: null,
        options: [
            { id: 'name', label: 'Name', editable: false },
            { id: 'email', label: 'Email', editable: true },
            { id: 'verified', label: 'Status', editable: true },
            { id: 'created_at', label: 'Created', editable: true },
            { id: 'roleName', label: 'Role', editable: true }
        ]
    }
];
export const UserAccessListTablePageSizeOptions = [
    { value: 10, label: '10 Users' },
    { value: 30, label: '30 Users' },
    { value: 50, label: '50 Users' }
];
