import React, { useEffect, useState } from "react";
import { useSettingsHandlers } from "@/presentation/providers/settings-handlers-provider";
import Skeleton from "react-loading-skeleton";
import { useIsMounted } from "@/presentation/hooks";
import SettingsSSOForm from "./settings-sso-form";
import SettingsSSOSupport from "./settings-sso-support";
const SettingsSSO = () => {
    const { settings } = useSettingsHandlers();
    const settingsHandler = settings;
    const [loading, setLoading] = useState(true);
    const [ssoData, setSsoData] = useState();
    const isMounted = useIsMounted();
    const refreshList = () => {
        if (isMounted.current) {
            setLoading(true);
        }
        settingsHandler
            .getSSO()
            .then((data) => {
            if (isMounted.current) {
                setSsoData(data);
            }
        })
            .catch(console.error)
            .finally(() => {
            if (isMounted.current) {
                setLoading(false);
            }
        });
    };
    useEffect(() => {
        refreshList();
    }, []);
    if (loading) {
        return React.createElement(Skeleton, { count: 12 });
    }
    const isSamlEnabled = (ssoData === null || ssoData === void 0 ? void 0 : ssoData.hasOwnProperty('id')) || false;
    return isSamlEnabled ? (React.createElement(SettingsSSOForm, { ssoData: ssoData })) : (React.createElement(SettingsSSOSupport, null));
};
export default SettingsSSO;
