import React from "react";
import { Container, FormField, Header, Toggle, SpaceBetween, Input, Textarea, RadioGroup, } from "@cloudscape-design/components-themed/components";
const SettingsSSOForm = ({ ssoData }) => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j;
    const isPageReadonly = true;
    return (React.createElement(SpaceBetween, { size: "xl" },
        React.createElement(Container, { header: React.createElement(Header, { variant: "h2" }, "Single Sign-On") },
            React.createElement(SpaceBetween, { size: "s" },
                React.createElement(FormField, { label: "Enable SSO", description: "" },
                    React.createElement(Toggle, { checked: true, disabled: isPageReadonly })))),
        React.createElement(Container, { header: React.createElement(Header, { variant: "h2" }, "Configuration") },
            React.createElement(SpaceBetween, { size: "s" },
                React.createElement(FormField, { label: "Connection Name" },
                    React.createElement(Input, { value: (ssoData === null || ssoData === void 0 ? void 0 : ssoData.name) || "-", disabled: isPageReadonly })),
                React.createElement(FormField, { label: "Sign-In URL" },
                    React.createElement(Input, { value: ((_a = ssoData === null || ssoData === void 0 ? void 0 : ssoData.options) === null || _a === void 0 ? void 0 : _a.signInEndpoint) || "", disabled: isPageReadonly })),
                React.createElement(FormField, { label: "Key" },
                    React.createElement(Textarea, { value: ((_b = ssoData === null || ssoData === void 0 ? void 0 : ssoData.options) === null || _b === void 0 ? void 0 : _b.cert) || "", disabled: isPageReadonly })),
                React.createElement(FormField, { label: "Enable Sign-Out" },
                    React.createElement(Toggle, { checked: !((_c = ssoData === null || ssoData === void 0 ? void 0 : ssoData.options) === null || _c === void 0 ? void 0 : _c.disableSignout) || false, disabled: isPageReadonly })),
                React.createElement(FormField, { label: "User ID Attribute" },
                    React.createElement(Input, { value: (ssoData === null || ssoData === void 0 ? void 0 : ssoData.id) || "", disabled: isPageReadonly })),
                React.createElement(FormField, { label: "Sign Request" },
                    React.createElement(Toggle, { checked: ((_d = ssoData === null || ssoData === void 0 ? void 0 : ssoData.options) === null || _d === void 0 ? void 0 : _d.signSAMLRequest) || false, disabled: isPageReadonly })),
                React.createElement(FormField, { label: "Sign Request Algorithm" },
                    React.createElement(Input, { value: ((_e = ssoData === null || ssoData === void 0 ? void 0 : ssoData.options) === null || _e === void 0 ? void 0 : _e.signatureAlgorithm) || "", disabled: isPageReadonly })))),
        React.createElement(Container, { header: React.createElement(Header, { variant: "h2" }, "IdP-Initiated SSO") },
            React.createElement(SpaceBetween, { size: "s" },
                React.createElement(FormField, null,
                    React.createElement(RadioGroup, { value: ((_g = (_f = ssoData === null || ssoData === void 0 ? void 0 : ssoData.options) === null || _f === void 0 ? void 0 : _f.idpinitiated) === null || _g === void 0 ? void 0 : _g.enabled) ? "accept" : "reject", items: [
                            {
                                value: "reject",
                                label: "Reject Requests",
                                description: "Reject unsolicited login requests sent to Auth0 by the IdP",
                                disabled: true,
                            },
                            {
                                value: "accept",
                                label: "Accept Requests",
                                description: "Accept unsolicited login requests sent to Auth0 by the IdP",
                                disabled: true,
                            },
                        ] })),
                React.createElement(FormField, { label: "Response Protocol" },
                    React.createElement(Input, { value: ((_j = (_h = ssoData === null || ssoData === void 0 ? void 0 : ssoData.options) === null || _h === void 0 ? void 0 : _h.idpinitiated) === null || _j === void 0 ? void 0 : _j.client_protocol) || "", disabled: isPageReadonly }))))));
};
export default SettingsSSOForm;
