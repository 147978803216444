import React, { useState, useEffect } from 'react';
import { DetailsTableEmptyState, TableEmptyState, TableHeader, TableNoMatchState } from '@/presentation/components/commons/common-components';
import { Pagination, CollectionPreferences, PropertyFilter, Table } from '@cloudscape-design/components-themed/components';
import { useCollection } from '@cloudscape-design/collection-hooks';
import { getFilterCounterText } from '@/presentation/components/commons/tableCounterStrings';
import { getFieldOnChange } from '@/presentation/components';
import { TABLE_FILTERS_SESSION_STORAGE, TABLE_SORTING_SESSION_STORAGE } from '@/presentation/common-utils/constants';
import { useLocation } from 'react-router-dom';
import { useLocalStorage } from '@/presentation/components/commons/localStorage';
import { useColumnWidths } from '@/presentation/components/commons/use-column-widths';
import { COLUMN_WIDTH_STORAGE_KEY, PREFERENCES_STORAGE_KEY } from '@/presentation/pages/constants-and-types';
import { ProfilesRulesTablePreferences, ProfilesRulesTableVisibleContentOptions, ProfilesRulesTablePageSizeOptions, ProfilesRulesColumnDefinitions, ProfilesRulesFilteringProperties, ProfilesRulesFilteringOptions } from './profile-rules-table-config';
import { makeNotification, makePropertyFilterI18nStrings, PagesRequestErrorHandler } from '@/presentation/pages';
import { useProfilesHandlers } from '@/presentation/providers';
import TableContainer from '@/presentation/components/table/table-container';
import { useNotifications } from "@/presentation/providers/notifications-provider";
import { manageCreatedUpdatedList } from '../common-config';
import { GetPropertyFilters, GetTableSortingColumn, SetPropertyFilters, SetTableSortingColumn } from '@/presentation/storage/misc-storage';
import { fetchUniquePropertyFilters, getPropertyFilteringOptions } from '@/presentation/common-utils/common-utils';
const ProfilesRules = (props) => {
    var _a, _b;
    const propsRules = props.rulesView || props.rules;
    const location = useLocation();
    const profilesRulesColumnDefinitions = ProfilesRulesColumnDefinitions(propsRules, location.pathname, !!(props === null || props === void 0 ? void 0 : props.rulesView));
    const [columnDefinitions] = useColumnWidths(COLUMN_WIDTH_STORAGE_KEY.PROFILES_VIEW_RULES_LIST, profilesRulesColumnDefinitions);
    const [preferences, setPreferences] = useLocalStorage(PREFERENCES_STORAGE_KEY.PROFILES_VIEW_RULES_LIST, ProfilesRulesTablePreferences);
    const [selectedItems, setSelectedItems] = useState(((_b = (_a = props.info) === null || _a === void 0 ? void 0 : _a.rules) === null || _b === void 0 ? void 0 : _b.attachedRules) || []);
    const [filteringOptions, setFilteringOptions] = useState(ProfilesRulesFilteringOptions);
    const ref = React.useRef(null);
    const [loading, setLoading] = useState(false);
    const [renderCount, setRenderCount] = useState(1);
    const sessionStorageVariableName = (props === null || props === void 0 ? void 0 : props.profileRulesView) ? "PROFILES_VIEW_RULES_LIST" : "PROFILES_FORM_RULES_LIST";
    const getSessionPropertyFilter = GetPropertyFilters(TABLE_FILTERS_SESSION_STORAGE[sessionStorageVariableName]);
    const getSessionPropertySorting = GetTableSortingColumn(TABLE_SORTING_SESSION_STORAGE[sessionStorageVariableName]);
    const defaultSortingState = {
        sortingColumn: getSessionPropertySorting ? getSessionPropertySorting === null || getSessionPropertySorting === void 0 ? void 0 : getSessionPropertySorting.sortingColumn : profilesRulesColumnDefinitions === null || profilesRulesColumnDefinitions === void 0 ? void 0 : profilesRulesColumnDefinitions[0],
        isDescending: getSessionPropertySorting ? getSessionPropertySorting === null || getSessionPropertySorting === void 0 ? void 0 : getSessionPropertySorting.isDescending : true
    };
    const { complianceMappings } = useProfilesHandlers();
    const complianceMappingsHandler = complianceMappings;
    const { pushNotifications, dismissNotification } = useNotifications();
    const [userFilteredOptionsList, setUserFilteredOptionsList] = useState(propsRules);
    const messageInfo = (props === null || props === void 0 ? void 0 : props.profileRulesView) ? 'No rules matching the specified filters are attached to this profile' : undefined;
    const emptyText = (props === null || props === void 0 ? void 0 : props.profileRulesView) ? React.createElement(DetailsTableEmptyState, { text: "No rules are attached to this profile" }) : React.createElement(TableEmptyState, { resourceName: "Rules" });
    const { items, actions, filteredItemsCount, collectionProps, propertyFilterProps, paginationProps } = useCollection(propsRules, {
        pagination: { pageSize: preferences.pageSize },
        sorting: { defaultState: defaultSortingState },
        selection: {},
        propertyFiltering: {
            defaultQuery: getSessionPropertyFilter,
            filteringProperties: ProfilesRulesFilteringProperties,
            empty: emptyText,
            noMatch: React.createElement(TableNoMatchState, { resourceName: 'rules', message: messageInfo })
        }
    });
    const onProfilesTableChange = getFieldOnChange('table', 'attachedRules', props.onChange);
    let visibleContent = preferences.visibleContent;
    if (props === null || props === void 0 ? void 0 : props.profileRulesView) {
        visibleContent = ['checkbox', ...visibleContent];
    }
    useEffect(() => {
        const currUserList = manageCreatedUpdatedList((props === null || props === void 0 ? void 0 : props.rulesView) || (props === null || props === void 0 ? void 0 : props.rules));
        setUserFilteredOptionsList(currUserList);
    }, [props.rulesView, props.rules]);
    let commonArgs = Object.assign(Object.assign({}, collectionProps), { loading: loading, loadingText: 'Loading Rules', resizableColumns: true, visibleColumns: visibleContent, columnDefinitions: columnDefinitions, wrapLines: preferences.wrapLines, items: items, pagination: React.createElement(Pagination, Object.assign({}, paginationProps)), onSortingChange: ({ detail }) => {
            SetTableSortingColumn(TABLE_SORTING_SESSION_STORAGE[sessionStorageVariableName], detail);
            actions.setSorting(detail);
        }, filter: (React.createElement(PropertyFilter, Object.assign({}, propertyFilterProps, { i18nStrings: makePropertyFilterI18nStrings({ filteringPlaceholder: 'Filter rules by type or value' }), countText: getFilterCounterText(filteredItemsCount), expandToViewport: true, filteringOptions: [...filteringOptions, ...userFilteredOptionsList], filteringProperties: ProfilesRulesFilteringProperties, onChange: ({ detail }) => {
                const filteredDetail = fetchUniquePropertyFilters(detail);
                SetPropertyFilters(TABLE_FILTERS_SESSION_STORAGE[sessionStorageVariableName], filteredDetail);
                actions.setPropertyFiltering(filteredDetail);
            } }))), preferences: React.createElement(CollectionPreferences, { title: "Preferences", confirmLabel: "Confirm", cancelLabel: "Cancel", disabled: false, preferences: preferences, onConfirm: ({ detail }) => {
                setPreferences(detail);
                setRenderCount(renderCount + 1);
            }, pageSizePreference: {
                title: 'Page Size',
                options: ProfilesRulesTablePageSizeOptions
            }, wrapLinesPreference: {
                label: 'Wrap Lines',
                description: 'If checked, lines will be wrapped to display all of the text'
            }, visibleContentPreference: {
                title: 'Visible Columns',
                options: ProfilesRulesTableVisibleContentOptions
            } }) });
    const profilesRulesCreateProps = {
        header: (React.createElement(TableHeader, { title: "Rules", selectedItems: selectedItems, totalItems: props.rules })),
        selectionType: 'multi',
        selectedItems: selectedItems,
        onSelectionChange: event => {
            setSelectedItems(event.detail.selectedItems);
            onProfilesTableChange(event);
        }
    };
    const profilesRulesViewUpdatedProps = {
        isItemDisabled: (item) => props.attachedRuleIds.includes(item.id)
    };
    if (props.profileRulesView) {
        commonArgs = Object.assign(Object.assign(Object.assign({}, commonArgs), props.rulesViewTableProps), profilesRulesViewUpdatedProps);
    }
    else {
        commonArgs = Object.assign(Object.assign({}, commonArgs), profilesRulesCreateProps);
    }
    const handleErrorResponse = (response) => {
        const getResponseMessage = PagesRequestErrorHandler(response);
        pushNotifications(makeNotification('error', getResponseMessage === null || getResponseMessage === void 0 ? void 0 : getResponseMessage.errorMessage, dismissNotification));
    };
    useEffect(() => {
        var _a;
        if (ref === null || ref === void 0 ? void 0 : ref.current) {
            const hashValue = (_a = window.location.hash) === null || _a === void 0 ? void 0 : _a.substring(1);
            if (hashValue === 'rules') {
                document.getElementById('rules').scrollIntoView();
            }
        }
    }, [ref === null || ref === void 0 ? void 0 : ref.current]);
    useEffect(() => {
        setLoading(true);
        let servicesFilterMapList = [];
        let providersFilterMapList = [];
        let uniqueComplianceFrameworks = [];
        items.forEach(filterData => {
            providersFilterMapList.push(filterData.provider);
            servicesFilterMapList.push(filterData.service);
        });
        complianceMappingsHandler.listComplianceMappingsForCurrentOrg().then(listMappings => {
            uniqueComplianceFrameworks = complianceMappingsHandler.getUniqueComplianceFrameworks(listMappings === null || listMappings === void 0 ? void 0 : listMappings.ComplianceMappings);
        }).catch(({ response }) => {
            handleErrorResponse(response);
        }).finally(() => {
            const complianceFrameworksFilteringOptions = complianceMappingsHandler.getUniqueComplianceFrameworkPropertyFilteringOptions(uniqueComplianceFrameworks, 'complianceFrameworks');
            const providersFilteringOptions = getPropertyFilteringOptions(providersFilterMapList, 'provider');
            const servicesFilteringOptions = getPropertyFilteringOptions(servicesFilterMapList, 'service');
            setFilteringOptions([...filteringOptions, ...providersFilteringOptions, ...servicesFilteringOptions, ...complianceFrameworksFilteringOptions]);
            setLoading(false);
        });
    }, []);
    return React.createElement("div", { ref: ref, id: "rules" },
        React.createElement(TableContainer, { key: renderCount },
            React.createElement(Table, Object.assign({}, commonArgs, { variant: "embedded" }))));
};
export default ProfilesRules;
